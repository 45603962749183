.grid-size-selector {
  &.is-wide {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    button {
      flex-grow: 1;
      border-radius: 0;
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 0;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 0;
      }
    }
  }
}