.editor-button {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  >div {
    width: calc(100% - 64px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  svg {
    pointer-events: none;
    width: 18px;
    height: 18px;
  }
  &.is-active {
    background-color: #363636;
    color: #FFFFFF;
    font-weight: bold;
    svg {
      polygon, path, rect, line {
        stroke: #FFFFFF;
        fill: #363636;
      }
    }
    #paint-bucket {
      polygon {
        fill: #FFFFFF;
      }
    }
    svg.invert-fill {
      path {
        stroke-width: 0;
        fill: #FFFFFF;
      }
    }

  }
  &:disabled {
    svg {
      opacity: 0.3;
    }
  }
  &.is-wide {
    width: 100%;
  }
}