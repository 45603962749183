@import '../variables.scss';

.design {
  background-color: $color-background-gray;
  min-height: 100%;
  padding-bottom: 75px;
}

.design__content {
  margin: auto;
  width: 600px;
  text-align: center;

  @media #{$mobile} {
    padding: 0 $padding-horizontal;
    width: 100%;
  }
}

.design__not-found {
  padding-top: 70px;
  p {
    margin-bottom: $padding-vertical;
  }
  a.button {
    display: block;
    width: 60%;
    margin: auto;
    margin-bottom: $padding-vertical;
  }
}

.design__title {
  margin: $padding-double;

  @media #{$mobile} {
    font-size: 20px;
  }
}

.design .error-box {
  margin-top: $padding-vertical * 2;
}

.design .variant-selector {
  margin-top: $padding-vertical * 3;
}

.design .upload-input {

  .design-name {
    margin-top: $padding-vertical;
    @include font-header();
    font-size: 32px;
  }
  .designer-name {
    @include font-light();
    font-size: 16px;
    margin-top: 6px;
  }

}
