@import '../variables.scss';

.checkout-shipping__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.checkout-shipping .checkout__input {
  display: inline-block;
  width: 431px;

  @media #{$mobile} {
    width: 100%;
  }
}

.checkout-shipping .checkout-shipping__country,
.checkout-shipping .checkout-shipping__name,
.checkout-shipping .checkout-shipping__save {
  width: 100%;
}
