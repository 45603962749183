@import '../variables.scss';

.cart-gift-certificates {

}

.cart-gift-certificates .review-line-item__thumbnail {
  background-color: $color-primary;
  background-image: url('../../assets/products/gift-certificate.png');
  background-size: contain;
}

.cart-gift-certificate-row__details p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}

.cart-gift-certificate-row__details p:last-child {
  margin-bottom: $padding-vertical;
}
