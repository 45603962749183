@import '../variables.scss';
@import '../base.scss';

.checkout-totals .loading-spinner__content {
  margin-top: 0;
}

.checkout-totals__line {
  @extend .clearfix;

  margin-bottom: ($padding-vertical / 2);

  @media #{$mobile} {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.checkout-totals__line-label {
  display: inline-block;
}

.checkout-totals__line-value {
  float: right;
}

.checkout-totals__total {
  border-top: 1px solid $color-border;
  font-size: 24px;
  margin-top: $padding-vertical;
  padding-top: $padding-vertical;
}

.checkout-totals__remove {
  color: $color-primary;
  font-size: 16px;
  padding-left: $padding-horizontal / 2;
}
