@import '../variables.scss';

$mobile-top-padding: 6px;

.review-line-item {
  display: table;
  margin-bottom: $padding-vertical;
  width: 100%;

  @media #{$mobile} {
    font-size: 16px;
    position: relative;
  }
}

.review-line-item__thumbnail-container {
  display: table-cell;
  width: 80px;
}

.review-line-item__thumbnail {
  background-position: center bottom;
  background-repeat: no-repeat;
  border-radius: 5px;
  height: 80px;
  width: 80px;
}

.review-line-item__thumbnail-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  font-size: 20px;
  border-radius: 5px;
  height: 80px;
  width: 80px;
}

.review-line-item--PTD01 .review-line-item__thumbnail {
  background-color: $color-magenta;
  background-image: url('../../assets/products/review-dress.png');
  background-size: 38px 70px;
}

.review-line-item--PTD01AG .review-line-item__thumbnail {
  background-color: $color-magenta;
  background-image: url('../../assets/products/review-dress-addon.png');
  background-size: 44px 70px;
}


.review-line-item--PTS01AG .review-line-item__thumbnail {
  background-color: $color-magenta;
  background-image: url('../../assets/products/review-shirt-addon.png');
  background-size: 37px 70px;
}

.review-line-item--PTS01 .review-line-item__thumbnail {
  background-color: $color-primary;
  background-image: url('../../assets/products/review-shirt.png');
  background-size: 34px 70px;
}

.review-line-item--PTB01AG .review-line-item__thumbnail,
.review-line-item--PTB01 .review-line-item__thumbnail {
  background-color: $color-primary;
  background-image: url('../../assets/products/review-beanie-doll.png');
  background-size: contain;
}

.review-line-item--PTL01AG .review-line-item__thumbnail,
.review-line-item--PTL01 .review-line-item__thumbnail {
  background-color: $color-primary;
  background-image: url('../../assets/products/review-leggings-doll.png');
  background-size: contain;
}

.review-line-item--GC .review-line-item__thumbnail {
  background-color: $color-primary;
  background-image: url('../../assets/products/gift-certificate.png');
  background-size: contain;
}

.review-line-item__content {
  display: table-cell;
  min-height: 80px;
  padding-left: $padding-horizontal;
  vertical-align: middle;

  @media #{$mobile} {
    padding-top: $mobile-top-padding;
    vertical-align: top;
  }
}

.review-line-item__title,
.review-line-item__quantity,
.review-line-item__price,
.review-line-item__delete {
  display: table-cell;
  vertical-align: middle;

  @media #{$mobile} {
    display: block;
    vertical-align: top;
  }
}

.review-line-item__title {
  text-align: left;
  width: 100%;
}

.review-line-item__variants {
  padding-top: 2px;

  @media #{$mobile} {
    padding-top: 30px;
  }
}

.review-line-item__variants .variant-selector__variant {
  margin-top: 4px;
}

.review-line-item__variants__pair {
  font-size: 15px;
  margin-top: 4px;
}

.review-line-item__variants__pair__separator {
  margin: 0 6px;
}

.review-line-item__quantity {
  padding-right: $padding-horizontal;

  @media #{$mobile} {
    left: 96px;
    position: absolute;
    top: 30px;
  }
}

.review-line-item__price {
  min-width: 80px;
  text-align: right;

  @media #{$mobile} {
    position: absolute;
    right: 28px;
    top: $mobile-top-padding;
  }

  @media #{$mobile-phone} {
    right: 0;
    top: 0;
  }
}

.review-line-item--editable .review-line-item__price {
  @media #{$mobile-phone} {
    top: 26px + $mobile-top-padding;
  }
}

.review-line-item__delete {
  min-width: 28px;
  padding-left: 10px;

  @media #{$mobile} {
    position: absolute;
    right: 0;
    top: $mobile-top-padding;
  }
}

.review-line-item__delete .icon-delete {
  cursor: pointer;
  display: none;
  position: relative;
  top: 2px;
}

.review-line-item--editable .review-line-item__delete .icon-delete {
  display: inline;
}

.review-line-item .view-size-chart {
  display: inline-block;
  margin-top: 3px;
  vertical-align: top;

  @media #{$mobile} {
    font-size: 16px;
  }
}
