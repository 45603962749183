@import '../variables.scss';

.my-account__orders {
  margin: $padding-vertical $padding-horizontal;
}
@media (max-width: 768px) {
  .my-account__orders {
    margin: 0;
  }
}

.my-account__order {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;  
  margin-bottom: $padding-vertical * 2;
}
.my-account__order:last-child {
  margin-bottom: 0;
}

.my-account__order-title {
  padding: $padding-vertical $padding-horizontal;
  position: relative;
}

.my-account__order-status {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #ccc;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}
  @media (max-width: 768px) {
    .my-account__order-status {
      position: relative;
      display: block;
      right: auto;
    }
  }

.order-status-pending { background-color: #f8e71c; }
.order-status-on-hold { background-color: #ffb100; }
.order-status-failed { background-color: #ff5900; }
.order-status-picture-issue { background-color: #ff0000; }
.order-status-refunded { background-color: #cccccc; }
.order-status-processing { background-color: #fd008f; }
.order-status-in-production { background-color: #6b05c9; }
.order-status-packing { background-color: #397fdb; }
.order-status-completed { background-color: #16c905; }
.order-status-na-completed { background-color: #006400; }
.order-status-cancelled { background-color: #484848; }

.my-account__order-item {
  padding: $padding-vertical $padding-horizontal;
  border-top: 1px solid $color-border;
  line-height: 25px;
}

.order-item__cols {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.order-item__thumbnail {
  display: table-cell;
  vertical-align: middle;
  width: 15%;
}
  @media (max-width: 768px) {
    .order-item__thumbnail {
      display: none;
    }
  }


.order-item__content {
  width: 65%;
}
  @media (max-width: 768px) {
    .order-item__content {
      width: 80%;
    }
  }

.order-item__content div {
  word-wrap: break-word;
}

.order-item__description {
  font-weight: bold;
}

.order-item__reorder {
  background-color: transparent;
  color: $color-primary;
  border: 0;
  padding: 0;
  margin: 0;
}

.order-item__content,
.order-item__price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 $padding-horizontal;
}
@media (max-width: 768px) {
  .order-item__content,
  .order-item__price {
    padding: 0;
  }
}

.order-item__message {
  padding-top: $padding-vertical;
  display: block;
  width: 100%;
}

.order-item__price {
  text-align: right;
  width: 20%;
}
  @media (max-width: 768px) {
    .order-item__price .dual-price .dual-price__subtotal {
      display: block;
      margin-right: 0;
      font-size: 90%;
    }
  }

.my-account__order-addresses {
  display: flex;
  border-top: 1px solid $color-border;
  padding: $padding-vertical $padding-horizontal;
}
  @media (max-width: 768px) {
    .my-account__order-addresses {
      flex-direction: column;
    }
  }

.my-account__order-address {
  flex: 1;
}
  @media (max-width: 768px) {
    .my-account__order-address:not(:last-child) {
      margin-bottom: $padding-vertical;
    }
  }

.my-account__order-address-label {
  font-weight: bold;
  margin-bottom: $padding-vertical/2;
}

.my-account__order-shipments {
  border-top: 1px solid $color-border;
  padding: $padding-vertical $padding-horizontal;
}

.my-account__order-shipment {
  display: flex;
  & > div {
    flex: 1;
  }
}

.my-account__order-payment {
  border-top: 1px solid $color-border;
  padding: $padding-vertical $padding-horizontal;
}

.my-account__order-payment-line {
  font-size: 16px;
  line-height: 24px;
  span { float: right; }
}

.my-account__order-payment-total {
  margin-top: $padding-vertical;
  padding-top: $padding-vertical;
  border-top: 1px solid $color-border;
  font-weight: bold;
  font-size: 20px;
  span { float: right; }
}

.my-account__order-payment-method {
  font-size: 16px;
  padding: $padding-vertical $padding-horizontal;
  border-radius: 10px;
  margin-top: $padding-vertical;
  background-color: #f2fdfa;
}