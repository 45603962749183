@import '../variables.scss';

.radio {
  border: 2px solid $color-border;
  border-radius: 30px;
  display: inline-block;
  height: 30px;
  position: relative;
  vertical-align: middle;
  width: 30px;
}

.radio__active {
  background-color: $color-primary;
  border-radius: 27px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  top: 3px; left: 3px;
  width: 20px;
}
