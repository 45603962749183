@import '../variables.scss';

.tools {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
 .tool-group {
    display: flex;
    flex-wrap: wrap;
    width: 90px;
    align-items: flex-start;
    justify-content: space-between;
    button {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
    .tool-break {
      display: none;
    }
    &:nth-child(1) {
      .toggle-grid {
        width: 100%;
        display: flex;
      }
    }
    &:nth-child(2) {
      .toggle-grid {
        display: none;
      }
      .grid-size-selector {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
  @media #{$desktop} {
    width: #{$tools_width};
    flex-direction: column;
    .tool-group {
      .tool-break {
        display: block;
        width: 100%;
        height: 20px;
      }
      &:last-child {
        margin-left: 0;
      }
      margin-bottom: 20px;
      .save-design {
        display: none;
      }
      &:nth-child(1) {
        .toggle-grid {
          display: none;
        }
      }
      &:nth-child(2) {
        .toggle-grid {
          display: flex;
        }
        .grid-size-selector {
          width: 40px;
          button {
            width: 40px;
          }
        }
      }
    }
  }
}