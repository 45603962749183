@import '../variables.scss';

.my-account__affiliate {
  margin: $padding-vertical $padding-horizontal;
  .affiliate__howitworks {
    margin-top: $padding-vertical * 2;
  }
}

.affiliate-status-tooltip {
  cursor: help;
  .fa-question-circle {
    color: $color-primary;
  }
  .react-tooltip-lite {
    background: $color-primary;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    width: 400px;
  }
  .react-tooltip-lite-arrow {
    border-color: $color-primary;
  }
}

.my-account__affiliate-info,
.my-account__affiliate-error {
  margin-bottom: 10px;
  font-size: 14px;
  .fa {
    margin-right: 5px;
  }
}

.my-account__affiliate-info {
  color: $color-info;
}

.my-account__affiliate-error {
  color: $color-error;
}

.my-account__affiliate-link {
  margin-top: $padding-vertical * 2;
}

.my-account__affiliate-links {
  margin-top: $padding-vertical * 2;
  display: flex;
  justify-content: space-between;
  .affiliate-code:first-child {
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .affiliate-code:last-child {
    flex-grow: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}
@media #{$mobile} {
  .my-account__affiliate-links {
    flex-direction: column;
    .affiliate-code:first-child {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom: none;
    }
    .affiliate-code:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-left: 2px solid $color-border;
    }
  }
}

.funraiser-option {
  font-size: 14px;
  margin-bottom: $padding-vertical;
}

.funraiser-info {
  border: 2px solid $color-border;
  background-color: $color-white;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  .funraiser-header {
    display: flex;
    justify-content: space-between;
    padding: $padding;
    cursor: pointer;
    h4 {
      font-size: 15px;
      font-weight: bold;
      .fa {
        margin-right: 5px;
      }
    }
    button {
      font-size: 15px;
      background-color: transparent;
      color: $color-primary;
      .fa {
        margin-left: 5px;
      }
    }
  }
  .funraiser-body {
    font-size: 15px;
    padding: $padding;
    padding-top: 0;
    p {
      margin-bottom: 5px;
      max-width: 60%;
    }
  }
  .my-account__affiliate-customize-form {
    margin-top: $padding-vertical;
    display: flex;
    input {
      margin-bottom: 0;
    }
    button {
      margin-left: $padding-horizontal;
    }
  }
}


.my-account__affiliate-payouts,
.my-account__affiliate-sales {
  margin-top: $padding-vertical * 2;
}

.my-account__affiliate-customize-link {
  display: block;
  margin: auto;
  background-color: $color-white;
  margin-top: $padding-vertical;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  border: 2px solid $color-affiliate-green;
  color: $color-affiliate-green;
  border-radius: 5px;
  font-weight: bold;
}

.my-account__affiliate-customize-link:hover {
  border-color: $color-affiliate-lt-green;
  color: $color-affiliate-lt-green;
}

.my-account__affiliate-customize {
  margin-top: $padding-vertical;
  border: 2px solid $color-border;
  background-color: $color-white;
  border-radius: 5px;
  h3 {
    padding: $padding;
    border-bottom: 2px solid $color-border;
  }
  > div {
    padding: $padding;
  }
  .my-account__affiliate-customize-form {
    display: flex;
    input {
      margin-bottom: 0;
    }
    button {
      margin-left: $padding-horizontal;
    }
  }
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.affiliate-stats,
.affiliate-settings {
  margin-top: $padding-vertical * 2;
  border: 2px solid $color-border;
  background-color: $color-white;
  border-radius: 5px;
  h3 {
    padding: $padding;
    border-bottom: 2px solid $color-border;
  }
  > div {
    padding: $padding;
  }
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.affiliate-stats-boxes {
  display: flex;
  justify-content: space-around;
  margin: $padding-vertical 0;
  >div {
    text-align: center;
    width: 50%;
    h4 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 10px;
    }
    p {
      font-size: 60px;
      font-weight: bold;
    }
  }
}

.affiliate-intro {
  margin-top: 20px;
}

.affiliate-settings {
  .checkout-field {
    label {
      margin-bottom: $padding-vertical;
    }
  }
}

.affiliate-settings__tos {
  h2, p {
    margin-bottom: 10px;
  }
  .affiliate-tos {
    margin-top: $padding-vertical;
    border: 2px solid $color-border;
    border-radius: 5px;
    height: 200px;
    overflow: auto;
  }
  .affiliate-settings__tos-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $padding-vertical;  
  }
}

.view-affiliate-tos {
  background: none;
  border: 0;
  color: $color-primary;
  font-weight: bold;
  display: block;
  margin-top: 20px;
  font-size: 18px;
  padding: 0;
  text-transform: none; 
}
  @media #{$mobile} {
    .view-affiliate-tos {
      margin-left: auto;
      margin-right: auto;
    }
  }