@import '../variables.scss';

.cart-line-item-row {

}

.cart-line-item-row .icon-edit {
  cursor: pointer;
  display: none;
  font-size: 14px;
  position: relative;
  top: 1px;
}

.cart-line-item-row--editable .icon-edit {
  display: inline;
}

.cart-line-item-row .icon-edit,
.cart-line-item-row .icon-delete {
  color: $color-light-gray;
}
