@import '../variables.scss';

.gift-price-calculator {
  button {
    border: 2px solid $color-primary;
    color: $color-primary;
    font-weight: bold;
  }
}

.gift-price-calculator__modal {
  background-color: #fff;
  border-radius: 5px;
  width: auto;
  max-width: 960px;
  padding: 0;
  height: auto;
  h1.header {
    text-align: center;
    margin: 20px 0;
  }
  @media #{$mobile} {
    width: 100%;
  }
}

.gift-price-calculator__table {
  width: 100%;
  th {
    text-align: left;
    padding: 10px 16px;
    background-color: $color-light;
    border: 1px solid $color-background-gray;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
  td {
    padding: 10px;
    font-size: 14px;
    border: 1px solid $color-light;
    img {
      margin-right: 14px;
      vertical-align: middle;
      width: 30px;
    }
  }
  .gift-price-calculator__total-label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 30px;
  }
  
  .gift-price-calculator__tax-note {
    line-height: 30px;
    font-size: 12px;
    font-style: italic;
  }
  
  .gift-price-calculator__total {
    line-height: 30px;
    font-weight: bold;
    font-size: 24px;
  }

  .gift-price-calculator__quantity-cell {
    >div {
      display: inline-block;
      vertical-align: middle;
      &:first-child {
        min-width: 80px;
      }
    }
  }

  .gift-price-calculator__title-cell {
    font-weight: bold;
  }
  
  .gift-price-calculator__product-cell {
    padding-left: 54px;
  }

  @media #{$mobile} {
    tr > td:first-child {
      img {
        width: 26px;
        margin: 0 0 5px 0;
      }
      span {
        display: block;
      }
      text-align: center;
    }
  }
}

.gift-price-calculator__buttons {
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  button {
    width: 250px;
    margin-right: 16px;
  }
  @media #{$mobile} {
    text-align: center;
  }
}