@import '../variables.scss';

.upload {
  background-color: $color-background-gray;
  min-height: 100%;
  padding-bottom: 75px;
}

.upload__content {
  margin: auto;
  width: 600px;
  text-align: center;

  @media #{$mobile} {
    padding: 0 $padding-horizontal;
    width: 100%;
  }
}

.upload__notice {
  margin-top: 30px;
  padding: 20px;
  background-color: white;
  border: 1px solid $color-border;
  text-align: left;
  h3 {
    margin-bottom: 20px;
  }
}

.upload__title {
  margin: $padding-double;

  @media #{$mobile} {
    font-size: 20px;
  }
}

.upload .error-box {
  margin-top: $padding-vertical * 2;
}

.upload .variant-selector {
  margin-top: $padding-vertical * 3;
}
