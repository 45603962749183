@import '../variables.scss';

.preset-price-selector {
  button {
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #CECECE;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 16px 16px 0;
    height: 50px;
  }
  .preset-price-selector__selected {
    border: 1px solid $color-primary;
    background-color: $color-light-orange;
  }
  .preset-price-selector__error {
    border: 2px solid $color-error;
    background-color: $color-light-red;
  }
  .input {
    width: auto;
    min-width: 150px;
    margin-bottom: 0;
    margin: 0 16px 16px 0;
  }
  .preset-price-selector__amount-typed {
    text-align: center;
  }
}