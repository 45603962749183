@import './variables.scss';

.pixel-this-app {
    .my-account__header,
    .user-store-info,
    .navigation-bar, 
    .footer {
        display: none;
    }
    .editor .header {
        margin-top: 10px;
    }
    .save-design {
        display: none;
    }
    .add-to-cart {
        width: 360px !important;
        margin-bottom: 20px;
        background-color: $color-primary;
        color: white;
        padding: 30px;
        &:disabled {
            background-color: $color-light-gray;
            opacity: 0.8;
            svg {
                opacity: 1;
            }
        }
        border-radius: 35px;
        border: 3px solid #ffffff;
        svg {
            polyline, line {
                stroke: #ffffff;
            }
            circle {
                fill: #ffffff;
            }
        }
    }
}