@import '../variables.scss';

.filter-dropdown {
  width: 100%;
}

.filter-dropdown__button {
  border: 1px solid $color-light-gray;
  border-radius: 4px;
  padding: 10px 16px;
  width: 100%;
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.filter-dropdown__menu {
  position: absolute;
  border: 1px solid $color-light-gray;
  border-radius: 4px;
  background-color: white;
  margin-top: 4px;
  width: 260px;
}

.filter-dropdown__menuitem {
  border-bottom: 1px solid $color-border;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: $color-border;
  }
  &:last-child {
    border-bottom: none;
  }
}