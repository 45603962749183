@import '../variables.scss';

header {
  position: fixed;
  z-index: 3;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  a {
    text-decoration: none;
  }
}

header.nav-open {
  -webkit-transform: translateX(-260px);
  transform: translateX(-260px);
}

.cd-logo {
  position: absolute;
  z-index: 2;
  left: 0;
  top: -11px;
  display: inline-block;
  height: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 161px;
  height: 124px;
}

.cd-logo a {
  display: block;
  line-height: 1;
}

.cd-logo a #rotating {
  position: absolute;
  left: 50%;
  margin-left: -19px;
  top: 50%;
  margin-top: -15px;
}

.cd-logo:hover a #rotating {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}

@media screen and (min-width: 1024px) {
  .cd-logo {
    margin-left: 30px;
  }
}

/* .cd-logo svg {
  width: 150px;
} */

.cd-morph-dropdown {
  position: relative;
  height: 51px;
}

.elementor-editor-active .cd-morph-dropdown {
  display: none;
}

@media screen and (max-width: 1200px) {
  .dropdown-list {
    position: fixed;
    z-index: 2001;
    top: 0;
    right: -260px;
    width: 260px;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /* background: #EC3177; */
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    overflow: hidden;
  }

  .nav-open .dropdown-list {
    -webkit-transform: translateX(-260px);
    transform: translateX(-260px);
  }
}

.cd-morph-dropdown::before {
  /* never visible - used in JS to check mq */
  content: 'mobile';
  display: none;
}

.cd-morph-dropdown .nav-trigger,
.mobile_cart-icon,
.mobile_account-icon {
  /* menu icon - visible on small screens only */
  position: absolute;
  top: 0;
  right: 0;
  height: 51px;
  width: 61px;
  /* replace text with icon */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}

.mobile_cart-icon,
.mobile_account-icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-right: 1px solid #E9E9E9;
  right: 61px;
}

.mobile_account-icon {
  right: 119px;
}

.mobile_cart-icon svg,
.mobile_account-icon svg {
  width: auto;
  height: auto;
  transform: scale(1.3);
}

@media screen and (min-width: 1201px),
print {

  .mobile_cart-icon,
  .mobile_account-icon {
    display: none;
  }
}

.cd-morph-dropdown .nav-trigger span,
.cd-morph-dropdown .nav-trigger span::after,
.cd-morph-dropdown .nav-trigger span::before {
  /* these are the 3 lines of the menu icon */
  position: absolute;
  background-color: #EA427F;
  height: 2px;
  width: 36px;
  border-radius: 5px;
}

.cd-morph-dropdown .nav-trigger span {
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
}

.cd-morph-dropdown .nav-trigger span::after,
.cd-morph-dropdown .nav-trigger span::before {
  content: '';
  left: 0;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.cd-morph-dropdown .nav-trigger span::before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cd-morph-dropdown .nav-trigger span::after {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.cd-morph-dropdown.nav-open .nav-trigger span {
  background-color: transparent;
}

.cd-morph-dropdown.nav-open .nav-trigger span::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cd-morph-dropdown.nav-open .nav-trigger span::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cd-morph-dropdown .main-nav {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav_wrapper .main-nav {
    display: none;
  }

  .nav_wrapper .main-nav.nav-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.cd-morph-dropdown .morph-dropdown-wrapper {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 1.2em 5%;
  -webkit-box-shadow: inset 0 1px 0 #e6e6e6;
  box-shadow: inset 0 1px 0 #e6e6e6;
  background-color: #FFFFFF;
}

.cd-morph-dropdown.nav-open .morph-dropdown-wrapper {
  display: block;
}

.cd-morph-dropdown .dropdown-list>ul>li {
  margin-bottom: 3.3em;
}

@media screen and (min-width: 769px),
print {
  .cd-morph-dropdown .dropdown-list>ul>li {
    visibility: hidden;
  }
}

.cd-morph-dropdown .label {
  display: block;
  font-size: 2.2rem;
  color: #1A1A1A;
  margin-bottom: .8em;
}

.cd-morph-dropdown .content li::after {
  clear: both;
  content: "";
  display: block;
}

.cd-morph-dropdown .gallery .content li {
  margin-bottom: 1.4em;
}

.cd-morph-dropdown .gallery .content a {
  display: block;
}

.cd-morph-dropdown .gallery .content a::before {
  /* icon on the left */
  content: '';
  display: inline-block;
  float: left;
  height: 54px;
  width: 54px;
  margin-right: .6em;
  background: red;
  border-radius: 50%;
  -webkit-transition: background .2s;
  transition: background .2s;
}

.cd-morph-dropdown .gallery .content a span,
.cd-morph-dropdown .gallery .content a em {
  display: block;
  line-height: 1.2;
}

.cd-morph-dropdown .gallery .content a em {
  font-size: 1.8rem;
  padding: .4em 0 .2em;
  color: #1A1A1A;
}

.cd-morph-dropdown .gallery .content a span {
  font-size: 1.4rem;
  color: #a6a6a6;
}

.cd-morph-dropdown .gallery .content a:hover::before {
  background-color: #1A1A1A;
}

.cd-morph-dropdown .links .content>ul>li {
  margin-top: 1em;
}

.cd-morph-dropdown .links-list a,
.cd-morph-dropdown .btn {
  display: block;
  margin-left: 14px;
  font-size: 2.2rem;
  line-height: 1.6;
}

.cd-morph-dropdown .links-list a:hover,
.cd-morph-dropdown .btn:hover {
  color: #1A1A1A;
}

.cd-morph-dropdown .content h2 {
  color: #a6a6a6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 20px 0 10px 14px;
}

@media screen and (min-width: 1201px),
print {
  .cd-morph-dropdown {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    text-align: center;
    transition: margin 1s;
  }

  .cd-morph-dropdown::before {
    content: 'desktop';
  }

  .cd-morph-dropdown .nav-trigger {
    display: none;
  }

  .cd-morph-dropdown .main-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .cd-morph-dropdown .main-nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0 auto;
  }

  .cd-morph-dropdown .main-nav ul.shop_icons {
    margin: 0;
  }

  .cd-morph-dropdown .main-nav ul.shop_icons li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    border-left: 1px solid #E9E9E9;
  }

  .cd-morph-dropdown .main-nav ul.shop_icons li a img {
    height: 18px;
  }

  .cd-morph-dropdown .main-nav>ul>li {
    display: inline-block;
    float: left;
  }

  .cd-morph-dropdown .main-nav>ul>li>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.8em;
    height: 51px;
    line-height: 51px;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: inherit;
    font-size: 14px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cd-morph-dropdown .main-nav>ul>li>a .fa {
    margin-top: -2px;
    margin-left: 5px;
  }

  .cd-morph-dropdown .main-nav>ul>li>a .fa-angle-down:before {
    font-size: 16px;
  }

  .cd-morph-dropdown.is-dropdown-visible .main-nav>ul>li>a {
    /* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
    opacity: .6;
  }

  .cd-morph-dropdown.is-dropdown-visible .main-nav>ul>li.active>a {
    opacity: 1;
  }

  .cd-morph-dropdown .morph-dropdown-wrapper {
    /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
    display: block;
    top: 51px;
    /* overwrite mobile style */
    width: auto;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    /* Force Hardware acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }

  .cd-morph-dropdown.is-dropdown-visible .morph-dropdown-wrapper {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .cd-morph-dropdown .dropdown-list {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform, width, height;
    -webkit-transition: visibility .3s;
    transition: visibility .3s;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }

  .no-csstransitions .cd-morph-dropdown .dropdown-list {
    display: none;
  }

  .cd-morph-dropdown .dropdown-list::before {
    /* dropdown top triangle */
    content: '';
    position: absolute;
    bottom: 100%;
    border: 8px solid transparent;
    border-bottom-color: #FFFFFF;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    top: -3px;
    left: 50%;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(45deg) translateX(-50%);
    transform: rotate(45deg) translateX(-50%);
    background: #fff;
    -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
    will-change: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .25s;
    transition-duration: .25s;
  }

  .cd-morph-dropdown .dropdown-list>ul {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
  }

  .cd-morph-dropdown.is-dropdown-visible .dropdown-list {
    visibility: visible;
    -webkit-transition: width .3s, height .3s, -webkit-transform .3s;
    transition: width .3s, height .3s, -webkit-transform .3s;
    transition: transform .3s, width .3s, height .3s;
    transition: transform .3s, width .3s, height .3s, -webkit-transform .3s;
  }

  .cd-morph-dropdown.is-dropdown-visible .dropdown-list::before {
    opacity: 1;
  }

  .cd-morph-dropdown .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    -webkit-transition: opacity .3s, visibility .3s;
    transition: opacity .3s, visibility .3s;
  }

  .cd-morph-dropdown .dropdown.active {
    opacity: 1;
    visibility: visible;
  }

  .cd-morph-dropdown .dropdown.move-left .content {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  .cd-morph-dropdown .dropdown.move-right .content {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  .cd-morph-dropdown .label {
    /* hide the label on bigger devices */
    display: none;
  }

  .cd-morph-dropdown .content {
    padding: 2.2em 1.8em;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    text-align: left;
    width: 300px;
  }

  .cd-morph-dropdown .content>ul::after {
    clear: both;
    content: "";
    display: block;
  }

  .cd-morph-dropdown .content>ul>li {
    width: 100%;
    margin-bottom: 24px;
  }

  .cd-morph-dropdown .content>ul>li:last-child {
    margin-bottom: 0;
  }

  .cd-morph-dropdown .content>ul>li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    color: #484848;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .cd-morph-dropdown .content>ul>li a img {
    margin-right: 10px;
  }

  .cd-morph-dropdown .content>ul>li.dropdown_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #F6F6F6;
    text-transform: none;
  }

  .cd-morph-dropdown .content>ul>li.dropdown_footer a {
    display: block;
    text-align: center;
    text-transform: none;
  }

  .cd-morph-dropdown .content>ul>li:nth-of-type(2n) {
    margin-right: 0;
  }

  .cd-morph-dropdown .gallery .content {
    /* you need to set a width for the .content elements because they have a position absolute */
    width: 510px;
    padding-bottom: .8em;
  }

  .cd-morph-dropdown .gallery .content li {
    margin-bottom: 1.8em;
  }

  .cd-morph-dropdown .links .content>ul>li {
    margin-top: 0;
  }

  .cd-morph-dropdown .links .content,
  .cd-morph-dropdown .button .content {
    width: 390px;
  }

  .cd-morph-dropdown .links-list a {
    font-size: 1.6rem;
    margin-left: 0;
  }

  .cd-morph-dropdown .btn {
    display: block;
    width: 100%;
    height: 60px;
    margin: 1.5em 0 0;
    font-size: 1.8rem;
    text-align: center;
    color: #FFFFFF;
    line-height: 60px;
    background: #DB6356;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .cd-morph-dropdown .btn:hover {
    background: #1A1A1A;
    color: #FFFFFF;
  }

  .cd-morph-dropdown .content h2 {
    font-size: 1.8rem;
    text-transform: none;
    font-weight: normal;
    color: #1A1A1A;
    margin: 0 0 .6em;
  }

  .cd-morph-dropdown .bg-layer {
    /* morph dropdown background */
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .cd-morph-dropdown.is-dropdown-visible .bg-layer {
    opacity: 1;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s, -webkit-transform .3s;
  }
}

.cd-main-nav .cd-subnav-trigger,
.cd-main-nav .go-back {
  display: none;
}

@media screen and (max-width: 1200px) {
  .cd-main-nav a {
    display: block;
    font-size: 18px;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    padding: .9em 2em .9em 1em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: #ffffff !important;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  }

  .cd-main-nav a.cd-subnav-trigger {
    display: block;
    position: relative;
  }

  .cd-main-nav a.cd-subnav-trigger:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    right: 20px;
    -webkit-transform-origin: 9px 50%;
    transform-origin: 9px 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .cd-main-nav a.cd-subnav-trigger:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    right: 20px;
    -webkit-transform-origin: 9px 50%;
    transform-origin: 9px 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .cd-main-nav a img {
    display: none;
  }

  .cd-main-nav .content ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: translateX(260px);
    transform: translateX(260px);
    /* background-color: #EC3177; */
    height: 100vh;
    z-index: 1;
  }

  .cd-main-nav .content.moves-out>ul {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .cd-main-nav .go-back {
    display: block;
    position: relative;
  }

  .cd-main-nav .go-back:before,
  .cd-main-nav .go-back:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    left: 20px;
    -webkit-transform-origin: 1px 50%;
    transform-origin: 1px 50%;
  }

  .cd-main-nav .go-back:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .cd-main-nav .go-back:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .cd-main-nav .go-back a {
    padding-left: 2em;
  }
}

.main-nav ul li:last-child a {
  border-left: 1px solid #E9E9E9;
  padding: 0 20px;
}

.dropdown-list {
    z-index: 2000;
}

.background-overlay {
  background-color: transparent;
  line-height: 0;
  padding: 120px 0px 200px 0px;
  position: relative;
}
  @media screen and (max-width: 768px) {
    .background-overlay {
      padding: 120px 0px 170px 0px;
    }
  }