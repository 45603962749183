@import '../variables.scss';

$step-height: 72px;

.step-bar {
  background: #ffffff;
  border-bottom: 1px solid $color-border;
  height: $step-height;
  width: 100%;

  @media #{$mobile} {
    height: auto;
  }
}

.step-bar__content {
  margin: auto;
  width: 1024px;

  @media #{$mobile} {
    width: 100%;
  }
}

.step-bar .header {
  background-image: url('../../assets/logo-icon.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 38px 38px;
  display: inline-block;
  height: $step-height;
  line-height: $step-height;
  padding-left: 52px;

  @media #{$mobile} {
    margin-left: $padding-horizontal;
  }
}

.step-bar__list {
  float: right;

  @media #{$mobile} {
    border-top: 1px solid $color-border;
    display: block;
    float: none;
    height: 34px;
    text-align: center;
  }
}

.step-bar__step {
  display: inline-block;
  font-size: 14px;
  line-height: $step-height;
  margin-left: $padding-horizontal * 2;
  position: relative;
  text-transform: uppercase;

  @media #{$mobile} {
    line-height: 34px;
    margin-left: $padding-horizontal;
  }
}

.step-bar__step:first-child {
  @media #{$mobile} {
    margin-left: 15px;
  }
}

.step-bar__step--completed {
  color: #5EAE07;
  margin-left: $padding-horizontal * 3;
}

.step-bar__step--future {
  opacity: 0.4;
}

.step-bar__number {
  margin-right: 10px;
}

.step-bar__checkmark {
  display: none;
  font-size: 20px;
  left: -28px;
  margin-right: 10px;
  position: absolute;
  top: 3px;
}

.step-bar__step--completed .step-bar__number {
  display: none;
}

.step-bar__step--completed .step-bar__checkmark {
  display: inline-block;
}

.step-bar--desktop {
  @media #{$mobile} {
    display: none;
  }
}

.step-bar--mobile {
  display: none;

  @media #{$mobile} {
    display: inline;
  }
}
