@import '../variables.scss';

.review-line-items {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  padding: $padding;
}

.review-line-items__subtotal {
  border-top: 1px solid $color-border;
  margin-top: $padding-vertical;
  padding-bottom: $padding-vertical;
  padding-right: 28px;
  padding-top: $padding-vertical * 2;
  text-align: right;

  @media #{$mobile} {
    padding-right: 0;
  }
}

.review-line-items__subtotal-label {
  float: left;
}
