.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba(0,0,0,0.6);
  outline: 0;
}

.modal-content {
  margin: 0 auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
  max-width: 1400px;
  max-height: 95%;
  overflow-y: scroll;
  margin-bottom: 50px;
  background: #fff;
  padding: 30px;
  background-color: #f4f4f4;
}

.modal-fullsize {
  max-height: 95%;
}
.modal-fullsize .modal-body {
  height: 100%;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: transparent;
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .modal {
      text-align: center;
      padding: 0 !important;
      padding-top: 0 !important;
  }

  .modal-dialog {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      margin: 0 0;
  }

  .modal-content {
      padding: 30px 0px 30px 0px;
      position: relative;
      max-height: 100%;
  }

  .close-modal {
    top: 5px;
    right: 5px;
    font-size: 26px;
    color: black;
  }

  .model-body {
      overflow-y: scroll !important;
      overflow-x: hidden !important;
  }
}

@media screen and (min-width: 767px) {
  .modal {
      padding-top: 80px;
  }
}