@import '../variables.scss';

.product-steps {
  display: table-row;
  width: 100%;

  @media #{$mobile} {
    display: block;
  }
}

.product-steps__step {
  color: #ffffff;
  display: table-cell;
  padding: $padding-vertical ($padding-horizontal * 1.5);
  padding-bottom: $padding-vertical * 2;
  text-align: center;

  @media #{$mobile} {
    display: block;
    width: 100%;
  }
}

.product-steps img {
  margin-bottom: $padding-vertical * 2;
  max-width: 100%;
}

.product-steps p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: $padding-vertical * 2;
}

.product-steps .button {
  display: inline-block;
  margin: auto;
  margin-bottom: ($padding-vertical / 2);
  width: auto;
}

.product-steps__header {
  font-size: 32px;
  margin-bottom: $padding-vertical;
}

.product-steps__number {
  background-color: #ffffff;
  border-radius: 800px;
  margin-right: $padding-horizontal;
  padding: 0 8px 4px 8px;
}

.product-steps__step--teal {
  background-color: #1cd8c5;
  background: linear-gradient(180deg, #2EE098 0%, #2ECF98 70%);
}

.product-steps__step--teal .product-steps__number {
  color: #1cd8c5;
}

.product-steps__step--shirt-teal {
  background-color: $color-teal;
}

.product-steps__step--shirt-teal .product-steps__number {
  color: $color-teal;
}

.product-steps__step--orange {
  background-color: $color-primary;
}

.product-steps__step--orange .product-steps__number {
  color: $color-primary;
}

.product-steps__step--orange .button.white {
  color: $color-primary;
}

.product-steps__step--yellow {
  background: linear-gradient(180deg, #F3EA00 0%, #ECC800 70%);
}

.product-steps__step--yellow .product-steps__number {
  color: $color-yellow;
}

.product-steps__step--magenta {
  background: linear-gradient(180deg, #EA427F 0%, #CE427F 70%);
}

.product-steps__step--magenta .product-steps__number {
  color: $color-magenta;
}

.product-steps__step--magenta .button.white {
  color: $color-magenta;
}

.product-steps__step--teal2 {
  background-color: #1cd8c5;
}

.product-steps__step--teal2 .button.white {
  color: #1cd8c5;
}
