@import '../variables.scss';

.button--reset {
  @include font-normal();

  background: none;
  border: none;
  cursor: pointer;
}

.button--reset:focus {
  outline: none;
}

.button {
  @include font-normal();

  border: 3px solid #ffffff;
  border-radius: 35px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  line-height: normal;
  padding: 12px 26px;
  transition: background 0.2s linear, opacity 0.15s linear, visibility 0s linear 0.15s;
  text-decoration: none;
}

.button.slim {
  padding: 6px 26px;
}

button.circle {
  width: 40px;
  height: 40px;
  padding: 0;
}

.button:hover {
  opacity: 0.8;
}

.button:focus {
  outline: none;
}

.button.white, .button--white {
  background: #ffffff;
  color: $color-primary;
}

.button.outline {
  background: none;
  border: 3px solid #ffffff;
}

.button.primary {
  background: $color-primary;
  border: 0;
}

.button.plain {
  background: none;
  border: none;
  color: $color-primary;
  text-decoration: underline;
  padding: 12px 6px;
}
.button.plain:hover {
  text-decoration: none;
}

.button.dark {
  background: $color-text;
  color: #ffffff;
}

.button.gray {
  background: #e8e8e8;
  color: #646464;
}

.button.magenta {
  background: $color-magenta;
  border: 0;
}

.button.teal {
  background: $color-teal;
  border: 0;
}

.button--dark-gradient {
  background-color: #424242;
  background: linear-gradient(180deg, #5F5F5F 0%, #424242 100%);
}

.button--outline-primary {
  background: none;
  border: 2px solid $color-primary;
  color: $color-primary;
}

.button[disabled] {
  background-color: #bbb !important; 
  cursor: default;
}

.circle-icon-button {
  display: inline-flex;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: $color-primary;
  border-radius: 50%;
  border: 1px solid $color-primary;
  transition: background 0.2s linear, opacity 0.15s linear, visibility 0s linear 0.15s;
  &:hover {
    opacity: 0.8;
  }
}