@import '../variables.scss';

.order-confirmation {
  background: $color-background-gray;
  min-height: 100%;
}

.order-confirmation__affiliate {
  .affiliate-header {
    .affiliate-header__image {
      width: 200px;
    }
  }
  @media #{$desktop} {
    .affiliate-header {
      .affiliate-header__image {
        margin-bottom: 0;
        margin-right: 30px;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.order-confirmation__container {
  margin: auto;
  max-width: 1024px;
}

.order-confirmation__header {
  font-size: 32px;
  margin-bottom: $padding-vertical;
  padding-top: 130px;
  text-align: center;

  @media #{$mobile} {
    padding-top: 70px;
  }
}

.order-confirmation__message {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  margin-top: $padding-vertical * 3;
  padding: ($padding-vertical * 1.5) ($padding-horizontal * 1.5);
  text-align: left;
  line-height: 26px;

  h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  @media #{$mobile} {
    margin-left: $padding-horizontal;
    margin-right: $padding-horizontal;
  }
}


.order-confirmation__step {
  float: left;
  margin-bottom: $padding-vertical * 2;
  max-width: 30%;
  text-align: center;

  @media #{$mobile} {
    max-width: 100%;
  }
}

.order-confirmation__step__middle {
  margin-left: 5%;
  margin-right: 5%;
}

.order-confirmation__step__image {
  padding: ($padding-vertical * 2) ($padding-horizontal * 2);
  width: 100%;

  @media #{$mobile} {
    width: 70%;
  }
}

.order-confirmation__step__title {
  font-size: 24px;
  margin-bottom: $padding-vertical;
}

.order-confirmation__step p {
  font-size: 16px;
  line-height: 26px;
}

.order-confirmation__social {
  background-color: $color-primary;
  padding: ($padding-vertical * 3) 0;
  text-align: center;
}

.order-confirmation__quote {
  color: #ffffff;
  background-color: $color-primary;
  padding-top: ($padding-vertical * 2);
  padding-bottom: ($padding-vertical * 3);
  text-align: center;
  blockquote {
    font-size: 25px;
  }
}

.order-confirmation__icon {
  text-align: center;
  padding: ($padding-vertical * 3) 0;
}


.order-confirmation__social__title {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: $padding-vertical * 3;
}

.order-confirmation__social__facebook {
  margin-right: $padding-horizontal * 3;
}

.order-confirmation__affiliate {
  margin-top: $padding-vertical * 2;
  padding-bottom: $padding-vertical;
  border: 1px solid $color-border;
  border-radius: 5px;
  background-color: #ffffff;
  .affiliate-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .affiliate-codes {
    display: flex;
    width: 100%;
  }
  .affiliate-code {
    flex-grow: 1;
    border: none;
    h3 {
      border-bottom: none;
      padding-bottom: 0;
    }
    .affiliate-code__body {
      padding-bottom: 0;
    }
  }
  .affiliate-customize {
    text-align: center;
    font-size: 14px;
    margin: $padding-vertical 0;
  }
  .affiliate__howitworks {
    border: none;
    h3 {
      border-bottom: none;
      padding-top: 0;
    }
  }
  .affiliate-code__share-links {
    justify-content: flex-end;
  }
}
@media #{$mobile} {
  .order-confirmation__affiliate {
    .affiliate-codes {
      flex-direction: column;
    }
  }
}