@import '../variables.scss';

.checkout-error__message {
  background-color: $color-error;
  padding: $padding;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
}

.checkout-error__hint {
  text-align: center;
  font-size: 14px;
  margin-top: $padding-vertical / 2;
  margin-bottom: $padding-vertical * 2;
}