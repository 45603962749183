@import '../variables.scss';

.segmented-control__button {
  border: 2px solid $color-text;
  border-radius: 0;
  color: $color-text;
  font-size: 18px;
  height: 50px;
  vertical-align: top;
  width: 225px;

  @media #{$mobile} {
    font-size: 14px;
    width: 120px;
  }
}

.segmented-control__button--active {
  background-color: $color-text;
  color: #ffffff;
}

.segmented-control__button:first-child {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.segmented-control__button:last-child {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
