@import './variables.scss';

.text--light {
  @include font-light();
}

.text--bold {
  @include font-bold();
}

.text--primary {
  color: $color-primary;
}
