@import '../variables.scss';

.review-addition {
  margin-bottom: 23px;
}

.review-addition__box {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  float: left;
  margin-right: 23px;
  overflow: hidden;
  text-align: left;
  width: 326px;

  @media #{$mobile} {
    margin-bottom: $padding-vertical;
    width: 100%;
  }
}

.review-addition__box:last-child {
  margin-right: 0;
}

.review-addition__image {
  background-color: $color-photo-background;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 167px;
}

.review-addition__image--PTS01 {
  background-image: url('../../assets/products/review-shirt.png');
  background-size: 72px 147px;
}

.review-addition__image--PTS01-grownup {
  background-image: url('../../assets/products/review-shirt-grownup.png');
  background-size: 75px 147px;
}

.review-addition__image--PTS01-combined {
  background-image: url('../../assets/products/review-shirt-combined.png');
  background-size: 160px 160px;
}

.review-addition__image--PTD01 {
  background-image: url('../../assets/products/review-dress.png');
  background-size: 79px 144px;
}

.review-addition__image--PTD01-womens {
  background-image: url('../../assets/products/review-dress-womens.png');
  background-size: 64px 144px;
}

.review-addition__image--PTD01-combined {
  background-image: url('../../assets/products/review-dress-combined.png');
  background-size: 160px 160px;
}

.review-addition__image--PTD01AG {
  background-image: url('../../assets/products/review-dress-addon.png');
  background-size: 93px 148px;
}

.review-addition__image--PTS01AG {
  background-image: url('../../assets/products/review-shirt-addon.png');
  background-size: 79px 148px;
}

.review-addition__image--PTB01AG {
  background-image: url('../../assets/products/review-beanie-doll.png');
  background-size: 107px 118px;
  background-position: center;
}
.review-addition__image--PTB01-grownup {
  background-image: url('../../assets/products/review-beanie-grownup.png');
  background-size: 163px 148px;
}
.review-addition__image--PTB01-kids {
  background-image: url('../../assets/products/review-beanie-kids.png');
  background-size: 137px 148px;
}

.review-addition__image--PTL01AG {
  background-image: url('../../assets/products/review-leggings-doll.png');
  background-size: 98px 130px;
  background-position: center;
}
.review-addition__image--PTL01 {
  background-image: url('../../assets/products/review-leggings-grownup.png');
  background-size: 164px 160px;
}
.review-addition__image--SCR01 {
  background-image: url('../../assets/products/review-scrunchie.png');
  background-size: 193px 130px;
}
.review-addition__image--SCR01-leggings {
  background-image: url('../../assets/products/review-scrunchie-leggings.png');
  background-size: 134px 130px;
  background-position: center;
}

.review-addition__image--PTFC01 {
  background-image: url('../../assets/products/review-face-cover.png');
  background-size: 195px 200px;
  background-position: top;
}
.review-addition__image--PTFC01AG {
  background-image: url('../../assets/products/review-face-cover.png');
  background-size: 145px 150px;
  background-position: bottom;
}


.review-addition__content {
  padding: $padding;
}

.review-addition h4 {
  display: inline-block;
  margin-bottom: 6px;
}

.review-addition__content p {
  margin-bottom: $padding-vertical;
  min-height: 64px;
}

.review-addition__price {
  margin-left: $padding-horizontal;
}

.review-addition__old-price {
  color: #A1A1A1;
  text-decoration: line-through;
}

.review-addition .button--outline-primary {
  font-size: 18px;
  width: 100%;
}
