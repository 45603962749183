@import 'variables.scss';

* {
  box-sizing: border-box;
}

html, body {
  @include font-normal();

  background-color: #ffffff;
  color: $color-text;
  font-size: 18px;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

p {
  margin: 0;
}

textarea, input {
  outline: none;
}

b, strong {
  @include font-bold();
}

input,
button,
select {
  @include font-normal();
}

button {
  border: 0;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.delete-button {
  background-color: transparent;
  color: $color-light-gray;
}

.delete-button:hover {
  text-decoration: underline;
  color: $color-primary;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  @include font-normal();
  padding: 0;
}


#root, .app {
  height: 100%;
  width: 100%;
}

.root__logo {
  height: 0;
  width: 0;
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.header {
  @include font-header();
}

.header--main {
  font-size: 34px;
}

.header--small {
  font-size: 24px;
}

.error-message {
  color: $color-error;
  padding: $padding;
  text-align: center;
}

.info-message {
  color: $color-info;
  padding: $padding;
  text-align: center;
}

.rounded-box {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  overflow: hidden;
  padding: $padding-plushalf;
  text-align: left;
}

.onoffswitch {
  width: 52px;
  height: 26px;
  border: 1px solid #ae4c00;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: all 120ms;
  i {
    color: #cecece;
    display: block;
    height: 22px;
    width: 22px;
    margin: 0 2px;
    background-color: white;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 120ms;
  }
  i.fa {
    font-size: 14px;
  }
}

.onoffswitch-off {
  border: 1px solid #a1a1a1;
  background-color: #cecece;
}

.onoffswitch-on {
  background-color: $color-primary;
  i {
    color: $color-primary;
    margin-left: 25px;
  }
}

.disabled__container {
  background: $color-background-gray;
  min-height: 100%;
}

.disabled__container {
  p { 
    max-width: 960px; 
    margin: auto;
  }
  .order-confirmation__message {
    margin-bottom: 100px;
    p {
      margin-bottom: 20px;
    }
  }
}

*[role=link] {
  cursor: pointer;
}

.transition-enter {
  opacity: 0;
}
.transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}