.save-design__modal {
  .header {
    margin-bottom: 26px;
  }
  @media #{$desktop} {
    max-width: 800px;
  }
}

.save-design__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.save-design__name {
  margin-top: 16px;
  flex-grow: 1;
  width: calc(100% - 20px);
}

.save-design__preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 10px;
    }
  }
  canvas {
    padding: 10px;
  }
}

.save-design__buttons {
  display: flex;
  justify-content: center;
  button {
    flex-basis: 200px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  justify-content: center;
  @media #{$desktop} {
    justify-content: flex-end;
  }
}