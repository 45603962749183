@import '../variables.scss';

.error-box {
  @include font-light();

  h3 {
    margin-bottom: 5px;
  }

  background-color: #f06666;
  border: 1px solid #eb3333;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  padding: ($padding-vertical / 1.5) $padding-horizontal;
  text-align: left;

  &.info-box {
    background-color: $color-info;
    border: 2px solid #fff;
  }
}
