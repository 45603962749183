@import '../variables.scss';

.loading-spinner__content {
  margin-top: 100px;
  padding: $padding-double;
  text-align: center;
  width: 100%;
}

.centered-spinner {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mini-spinner {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  animation: spinner-animation 0.8s infinite linear;
  border: 3px solid;
  border-color: $color-background-gray $color-background-gray $color-background-gray $color-primary;
  border-radius: 50%;
  display: inline-block;
  height: 74px;
  margin: auto;
  width: 74px;
}

.mini-spinner > .loading-spinner {
  height: 36px;
  width: 36px;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
