@import '../variables.scss';

.canvas-group {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  @media #{$desktop} {
    flex-basis: auto;
  }
  .canvas {
    position: relative;
    margin-bottom: 20px;
    canvas {
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }
  }
  .show-grid {
    canvas {
      border-right: 1px solid rgba(219,219,219,0.5);
      border-bottom: 1px solid rgba(219,219,219,0.5);
    }
  }
  .template-overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    svg {
      stroke: rgba(0,0,0,0.5);
      stroke-width: 10;
      polygon, path {
        fill: none;
      }
    }
  }
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
  }
}

#canvas-container {
  touch-action: none;
}
