@import '../variables.scss';

.cart-empty {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  padding: ($padding-vertical * 1.5) ($padding-horizontal * 1.5);
}

.cart-empty__text {
  float: left;
  line-height: 44px;

  @media #{$mobile} {
    float: none;
    line-height: 26px;
    margin-bottom: $padding-vertical;
    text-align: center;
  }
}

.cart-empty .button {
  float: right;

  @media #{$mobile} {
    float: none;
    text-align: center;
    width: 100%;
  }
}

.cart-empty__icon {
  background-image: url('../../assets/cart/cart-empty.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 209px;
  margin: ($padding-vertical * 2) auto;
  width: 214px;
}
