@import '../variables.scss';

.upload-input {
  background: #ffffff;
  border: 1px dashed $color-border;
  border-radius: 5px;
  height: 350px;
  margin: ($padding-vertical * 2) auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.upload-input--has-image {
  height: auto;
  padding: $padding-double;
  padding-bottom: $padding-vertical;
}

.upload-input--css-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .upload-input__remove {
    margin: 0;
    background-color: $color-white;
    opacity: 0.5;
  }
  &:hover .upload-input__remove {
    opacity: 0.85;
  }
}

.upload-input__icon {
  background-image: url('../../assets/products/upload-icon.png');
  background-size: 100% 100%;
  height: 84px;
  margin: 0 auto $padding-vertical auto;
  width: 58px;
}
.upload-input__icon.fa {
  background-image: none;
  font-size: 60px;
  line-height: 84px;
  color: $color-primary;
}

.upload-input label {
  cursor: pointer;
  display: block;
  height: 100%;
  padding-top: 90px;
  width: 100%;
}

.custom-upload-input {
  cursor: pointer;
}

.custom-upload-input input {
  display: none;
}

.upload-input input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.upload-input__loading {
  background: #ffffff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.upload-input__image {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.upload-input__image-placeholder {
  padding: 30px 5px;
  color: $color-light-gray;
  border: 1px solid $color-light-gray;
  .fa {
    display: block;
    font-size: 40px;
    margin-bottom: 10px;
  }
}


.upload-input__remove {
  background: none;
  color: $color-error;
  margin-top: 6px;
}

.upload-input__remove .icon {
  left: -2px;
  position: relative;
  top: 2px;
}
