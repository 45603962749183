@import './variables.scss';

.forgot-password__container {
  background: #f8f8f8;
  min-height: 100%; 
}

.forgot-password {
  margin: auto;
  max-width: 1024px;
  padding-top: 130px;
}

.forgot-password__header {
  font-size: 32px;
  margin-bottom: 16px;
  padding-top: 130px;
  text-align: center; 
}

.forgot-password__button {
  margin-bottom: $padding-vertical;
  width: 100%;  
}

.forgot-password__instructions {
  line-height: 24px;
  margin-bottom: $padding-vertical * 2;
}