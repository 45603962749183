@import '../variables.scss';
@import '../base.scss';

.cart-pricing {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  padding: $padding;
  width: 100%;

  @media #{$mobile} {
    border-radius: 0;
    float: none;
    margin-bottom: 0;
    width: 100%;
    z-index: 1000;
  }
}

.cart-pricing__line {
  display: flex;
  align-items: flex-start;

  margin-bottom: ($padding-vertical / 2);

  @media #{$mobile} {
    font-size: 14px;
    margin-bottom: 4px;
  }
}


.cart-pricing__line-label {
  flex-grow: 2;
  word-break: break-word;
  .funraiser-info {
    color: $color-light-gray;
    cursor: pointer;
  }
}

.cart-pricing__line-action {
  color: $color-primary;
  font-size: 14px;
  padding-left: $padding-horizontal / 2;
}

.cart-pricing__line-value {
  min-width: 30%;
  text-align: right;
}

.cart-pricing__total {
  font-size: 20px;
  margin-top: $padding-vertical;
}

.cart-pricing__total .cart-pricing__line-label {
  padding-top: 3px;
}

.cart-pricing__total .cart-pricing__line-value {
  font-size: 24px;
}

.cart-pricing__line-credit .cart-pricing__line-value {
  color: $color-green;
}

.cart-pricing__invalid, 
.cart-pricing__invalid .cart-pricing__line-label,
.cart-pricing__invalid .cart-pricing__line-value {
  text-decoration: line-through;
  color: red;
}

.cart__checkout {
  margin-top: $padding-vertical * 2;
  text-align: center;
  width: 100%;

  @media #{$mobile} {
    margin-top: $padding-vertical;
  }
}

.cart-pricing__continue {
  margin-top: $padding-vertical;
  text-align: center;
  width: 100%;

  @media #{$mobile} {
    display: none;
  }
}

.cart-pricing__store-credit-alert {
  font-size: 16px;
  text-align: center;
}

.cart-pricing__store-credit-alert button {
  color: $color-primary;
}
