@import '../variables.scss';

.report-table {
  border: 2px solid $color-border;
  background-color: $color-white;
  border-radius: 5px;
  h3 {
    padding: $padding;
    border-bottom: 2px solid $color-border;
  }
  table {
    width: 100%;
    tr {
      border-bottom: 2px solid $color-border;
    }
    tbody {
      tr:last-child {
        border-bottom: none;
      }
      td {
        text-align: center;
      }
    }
    th {
      padding: 4px 8px;
      font-size: 16px;
    }
    td {
      padding: 8px;
      font-size: 16px;
    }
    th {
      font-weight: bold;
    }
  }
}