@import '../variables.scss';

.cart-magic-code {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  padding: $padding;
  width: 100%;
}

.cart-magic-code__cta {
  cursor: pointer;
  vertical-align: middle;
}

.cart-magic-code__icon {
  background-image: url('../../assets/cart/magic-icon.png');
  background-size: 38px 35px;
  display: inline-block;
  height: 35px;
  margin-right: $padding-horizontal;
  vertical-align: middle;
  width: 38px;
}

.cart-magic-code__info {
  height: 35px;
  width: 38px;
  font-size: 22px;
  color: $color-light-gray;
  margin-left: $padding-horizontal;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cart-magic-code__info-popover {
  font-size: 16px;
  background-color: $color-light;
  border-radius: 5px;
  padding: $padding-horizontal;
  max-width: 350px;
}

.cart-magic-code__cta span {
  position: relative;
  top: 2px;
}

.cart-magic-code__form {
  margin-top: $padding-vertical;
}

.cart-magic-code__form .button {
  width: 100%;
}

.cart-magic-code__success,
.cart-magic-code__error {
  border-radius: 5px;
  font-size: 14px;
  margin-top: $padding-vertical;
  padding: $padding;
  text-align: center;
}

.cart-magic-code__success {
  background-color: #e9f7da;
  border: 1px solid #7ED321;
}

.cart-magic-code__error {
  background-color: #ffe5e5;
  border: 1px solid #FF0000;
}

.cart-magic-code__loading {
  text-align: center;
}