@import '../variables.scss';

.palette-group {
  display: flex;
  .color-bank-button {
    height: 64px;
  }
  .color-bank-button:disabled {
    opacity: 0.5;
    background-color: #eee;
  }
  @media #{$desktop} {
    width: auto !important;
    .color-bank-button {
      height: 26px;
    }
    .color-bank-button:first-child {
      margin-left: 20px;
      order: 1;
    }
    .color-bank-button:last-child {
      margin-left: 10px;
      order: 2;
    }
  }
  .color-palette {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 8px;
    @media #{$desktop} {
      width: #{$canvas_width};
      margin: 0 -3px 0 0;
    }
  }
  .color-break {
    width: 100%;
    height: 4px;
    flex-basis: 100%;
    @media #{$desktop} {
      display: none;
    }
  }
  .color-entry {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    border: 2px solid #ddd;
    &.current {
      border-color: black;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      display: block;
      width: 8px;
      height: 8px;
      background-color: white;
      border: 1px solid #ebebeb;
    }
    @media #{$desktop} {
      width: 26px;
      height: 26px;
    } 
  }
}