@import '../variables.scss';

.footer {
  background: #ffffff;
  font-size: 13px;
  padding: ($padding-vertical * 3) ($padding-horizontal * 3);
  text-align: center;
}

.footer h6 {
  font-size: 14px;
  margin-bottom: $padding-vertical;
  text-transform: uppercase;
}

.footer__section {
  max-width: 350px;
  width: 33%;
}

.footer__links {
  display: inline-block;
  vertical-align: top;

  @media #{$mobile} {
    width: 100%;
  }
}

.footer__links li {
  margin-top: $padding-vertical;
}

.footer__links a {
  text-decoration: none;
}

.footer__newsletter {
  display: inline-block;

  vertical-align: top;

  @media #{$mobile} {
    margin: ($padding-vertical * 4) 0;
    width: 100%;
  }
}

.footer__newsletter input {
  border: 2px solid $color-text;
  border-radius: 50px;
  text-align: center;
  display: block;
  font-size: 13px;
  padding: $padding;
  width: 100%;
}

.footer__newsletter-notice {
  margin-top: 10px;
  font-size: 22px;
}

.footer__newsletter .button {
  margin-top: $padding-vertical;
  width: 100%;
}

.footer__social {
  display: inline-block;
  vertical-align: top;

  @media #{$mobile} {
    width: 100%;
  }
}

.footer__social .icon,
.footer__social .fa {
  font-size: 36px;
  display: inline-block;
  margin-bottom: $padding-vertical * 1.5;
}



.footer__social-link {
  margin-right: $padding-horizontal;
}

.footer__social-link:last-child {
  margin-right: 0;
}
