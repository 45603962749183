@import '../variables.scss';

.checkout-billing {
  text-align: center;
}

.checkout-billing .checkout__input {
  padding-top: 13px;
  margin-bottom: 0;
  width: 200px;

  @media #{$mobile} {
    margin-bottom: $padding-vertical;
    width: 100%;
  }
}

.checkout-billing .checkout-billing__card {
  display: block;
  margin: 0 auto;
  width: 480px;

  @media #{$mobile} {
    border: 0;
    box-shadow: none;
    padding: 0;
    margin-bottom: $padding-vertical;
    width: 100%;
  }
}

.checkout-billing__save {
  text-align: center;
  margin-top: $padding-vertical * 2;
  animation: growBounce 0.15s ease-out;
}

@keyframes growBounce {
  0%   { height: 0px; opacity: 0; }
  70%  { height: 35px; }
  100% { height: 30px; opacity: 1; }
}

.checkout-billing__option 

.checkout-billing__date {
  margin-right: $padding-horizontal * 2;
}

.checkout-billing__option input[type=radio] {
  margin-right: $padding-horizontal;
  width: 20px;
  height: 20px;
}

.checkout-billing__option .checkout-billing__card-input {
  flex-grow: 2;
}

.checkout-billing__option {
  padding: 15px;
  display: flex;
  margin-bottom: $padding-vertical;
  align-items: center;
}

.checkout-billing__saved {
  border-radius: 5px;
  background-color: $color-light-blue;
  font-weight: bold;
}

.checkout-billing__option:last-child {
  margin-bottom: 0;
}
