@import '../variables.scss';

.quantity-selector {
  white-space: nowrap;
}

.quantity-selector__button {
  background-color: #ffffff;
  border: 1px solid #CFCFCF;
  border-radius: 25px;
  color: #CFCFCF;
  font-size: 14px;
  height: 20px;
  padding: 0;
  vertical-align: middle;
  width: 20px;
}

.quantity-selector__text {
  background: #ffffff;
  display: inline-block;
  vertical-align: middle;
  min-width: 38px;
  text-align: center;
}
