@import '../variables.scss';

.template-chooser {
  padding: ($padding-vertical * 4) $padding-horizontal;
  text-align: center;
}

.template-chooser__icon {
  height: 227px;
  margin-bottom: $padding-vertical * 2;
  width: 227px;
}

.template-chooser__select {
  margin-bottom: $padding-vertical;
  margin-top: $padding-vertical * 2;
  width: 290px;
}

.template-chooser__button {
  background: none;
  display: block;
  font-size: 17px;
  margin: auto;
  text-transform: none;
}

.template-chooser__button .icon {
  font-size: 28px;
  position: relative;
  top: 6px;
}
