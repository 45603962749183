@import '../variables.scss';

.user-store__container {
  background: #f8f8f8;
  min-height: 100%;
}

.user-store__hero {
  padding-top: 50px;
  padding-bottom: 20px;
}

.user-store__profile-image {
  border: 1px solid white;
  background-color: black;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 180px;
  height: 180px;
}

.user-store__product-image {
  background-size: cover;
  background-color: $color-border;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 420px;
  height: 520px;
  margin-bottom: 10px;
  .fa {
    font-size: 50px;
    color: white;
  }
  canvas {
    max-width: 400px;
    max-height: 500px;
  }
  @media #{$mobile} {
    width: 100%;
  }
}

.user-store__product-thumbnails {
  width: 440px;
  margin-left: -9px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media #{$mobile} {
    width: 100%;
    justify-content: space-evenly;
    margin-left: 0;
  }
}

.user-store__product-thumbnail-label {
  background-color: $color-border;
  margin-left: 9px;
  margin-right: 9px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px;
  font-weight: bold;
  @media #{$mobile} {
    font-size: 12px;
  }
}

.user-store__product-thumbnail {
  background-color: $color-border;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  position: relative;
  width: 128px;
  height: 168px;
  margin: 9px;
  canvas {
    max-width: 118px;
    max-height: 158px;
  }
  .thumbnail-controls {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    display: flex;
    background-color: rgba(80,80,80,0.5);
    padding: 4px 8px;
    width: 100%;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    color: #aaa;
    button {
      background-color: transparent;
      color: white;
      padding: 0;
      margin: 0;
    }
    .fa-arrows {
      color: white;
      cursor: move;
    }
  }
  @media #{$mobile} {
    width: 96px;
    height: 126px;
  }
}

.user-product-details {
  display: flex;
  justify-content: space-around;
  @media #{$mobile} {
    flex-direction: column;
  }
  .user-product-options,
  .user-product-info {
    padding: 16px;
    min-height: 96px;
    border: 1px solid $color-border;
    width: 400px;
    @media #{$mobile} {
      width: 100%;
      margin-top: 16px;
    }
  }
  .user-product-options-disabled {
    pointer-events: none;
    > * {
      opacity: 0.5;
    }
  }
  .user-product-info { 
    background-color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    h3 {
      @include font-header();
      font-size: 28px;
      color: #484848;
      margin-bottom: $padding-vertical;
      margin-right: 80px;
    }
    .user-product-price {
      @include font-bold();
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 28px;
    }
    .circle-icon-button {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    p {
      @include font-normal();
      font-size: 18px;
      line-height: 25px;
    }
  }
  .user-product-options {
    border-top: 0;
    background-color: #f8f8f8;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .product-selector {
      .product-selector__product {
        min-height: 64px;
      }
      .product-selector__image {
        width: 64px;
        height: 64px;
      }
      .product-selector__title {
        display: none;
      }
    }
    .view-size-chart {
      text-align: left;
      margin-bottom: $padding-vertical;
    }
    .button {
      width: 100%;
    }
  }
  .store-owner {
    margin-top: 8px;
    display: flex;
    align-items: center;
    .user-store__profile-image {
      margin-right: 6px;
      width: 35px;
      height: 35px;
    }
    a {
      margin-left: 6px;
    }
  }
}

.other-designs-by-user {
  margin-top: 32px;
  h2 {
    text-align: center;
  }
}

.shops-logo {
  position: absolute;
  width: 960px;
  height: 150px;
  top: 125px;
  left: calc(50% - 480px);
  background-image: url('../../assets/userstores/designerShops.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300% 300%;
  @media #{$mobile} {
    width: 100%;
    left: 0;
    background-size: 150% 150%;
  }
}

.shops-controls {
  display: flex;
  >* {
    width: 260px;
  }
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(180,180,180,0.25);
  padding: 32px 20px;
  width: 930px;
  margin: auto;
  margin-top: 240px;
  border-radius: 8px;
  @media #{$mobile} {
    width: 100%;
    flex-direction: column;
    >*:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.user-store-products {
  width: 950px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 128px;
  display: flex;
  flex-flow: row wrap;
    @media #{$mobile} {
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      margin: 16px 0;
    }
}

.user-store-products-center {
  justify-content: center;
}

img.stroke-image,
canvas.stroke-image {
  $shadow-color: rgba(0,0,0,0.35);
  filter:
    drop-shadow(1px 1px 0 $shadow-color)
    drop-shadow(-1px -1px 0 $shadow-color)
    drop-shadow(1px -1px 0 $shadow-color)
    drop-shadow(-1px 1px 0 $shadow-color)
    drop-shadow(1px 0px 0 $shadow-color) 
    drop-shadow(-1px 0px 0 $shadow-color)  
    drop-shadow(0px 1px 0 $shadow-color) 
    drop-shadow(0px -1px 0 $shadow-color);
}

.user-product {
  a {
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  margin: 0 8px 24px 8px;
  background-color: white;
  border: 1px solid $color-border;
  box-shadow: 0px 0px 5px 0px rgba(180,180,180,0.25);
  border-radius: 8px;
  flex-basis: 300px;
    @media #{$mobile} {
      flex-basis: auto;
      width: calc(100% - 32px);
      margin-bottom: 16px;
    }
  color:  #484848;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  .image {
    margin: 16px auto;
    border-radius: 8px;
    width: 260px;
    height: 310px;
    background-size: cover;
    margin-bottom: 26px;
    background-color: $color-border;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa {
      color: white;
      font-size: 60px;
    }
    img, canvas {
      max-width: 240px;
      max-height: 290px;
    }
  }
  .info {
    flex-grow: 1;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    .price {
      margin-left: 8px;
    }
    padding: 0 16px;
    &:last-child {
      margin-bottom: 26px;
    }
    .name {
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;
    }
  }
  .description {
    padding: 0 16px;
    margin-top: 8px;
  }
  .product-designer {
    text-decoration: none;
    padding: 0 16px;
    display: block;
    .user-store__profile-image {
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
      width: 25px;
      height: 25px;
    }
    width: 295px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
    margin-bottom: 26px;
    @include font-normal();
    font-size: 16px;
  }
}

.button-bar-tooltip {
  .react-tooltip-lite {
    background: black;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    text-align: center;
  }
  .react-tooltip-lite-arrow {
    border-color: black;
  }
}

.user-product-controls {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  >.user-product-buttons, >button, >a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color:  #484848;
    background-color: transparent;
    border-top: 1px solid $color-border;
    padding: 16px 20px;
    font-size: 16px;
    .fa {
      font-size: 20px;
    }
  }
  .user-product-buttons {
    display: flex;
    justify-content: space-evenly;
    a, button {
      flex-grow: 1;
      border-right: 1px solid $color-border;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        margin-bottom: 10px;
      }
      font-size: 12px;
      &:last-child {
        border-right: none;
      }
    }
  }
  .order-product {
    text-align: left;
  }
}