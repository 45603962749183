@import '../variables.scss';

.input {
  @include font-normal();

  border: 1px solid #CECECE;
  border-radius: 5px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.06);
  color: $color-text;
  font-size: 16px;
  height: 50px;
  margin-bottom: $padding-vertical * 2;
  padding: 0 $padding-horizontal;
  transition: border 0.2s linear;
  width: 100%;

  @media #{$mobile} {
    font-size: 14px;
    margin-bottom: $padding-vertical;
  }
}

textarea.input {
  padding: $padding;
  height: 100px;
}

.checkbox {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.textarea {
  padding: $padding;
}

.input:focus {
  border-color: $color-primary;
}

.input::placeholder {
  @include font-light();
  color: #A1A1A1;
}

.select {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  color: $color-text;
  font-size: 16px;
  height: 50px;
}

.checkout-select-field-wrapper {
  position: relative;
}

.checkout-select-field {
  appearance: none;
  color: $color-text;
  font-size: 16px;
  width: 100%;
  margin-bottom: $padding-vertical * 2;
  border: 1px solid #CECECE;
  height: 50px;
  text-align: left;
  line-height: 48px;
  padding-left: $padding-horizontal;
  border-radius: 5px;

  @media #{$mobile} {
    font-size: 14px;
    margin-bottom: $padding-vertical;
  }

  option:first-child {
    @include font-light();
    color: #A1A1A1;
  }
  option {
    color: #000;
  }
}

.checkout-select-field::-ms-expand {
  display: none;
}

.checkout-select-field.placeholder-value {
  @include font-light();
  color: #A1A1A1;  
}

.checkout-select-field-arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
 * Override react-select styles
 */

.select-dropdown {
  color: $color-text;
  font-size: 16px;
  width: 100%;
  margin-bottom: $padding-vertical * 2;

  @media #{$mobile} {
    font-size: 14px;
    margin-bottom: $padding-vertical;
  }
}

.select-dropdown .Select-control {
  border: 1px solid #CECECE;
  height: 50px;
  text-align: left;
}

.select-dropdown .Select-control .Select-value,
.select-dropdown .Select-placeholder,
.select-dropdown .Select-input {
  line-height: 48px;
  padding-left: $padding-horizontal;
}

.select-dropdown .Select-placeholder {
  color: #A1A1A1;
}

.select-dropdown .Select-menu-outer {
  border: 1px solid $color-primary;
  border-top: 0;
}

.select-dropdown .Select-option.is-focused {
  background-color: #ffe2cc;
}

.select-dropdown.is-focused > .Select-control {
  border-color: $color-primary;
  box-shadow: none;
}

.select-dropdown .Select-arrow-zone {
  width: 40px;
}

.select-dropdown .Select-arrow-zone i {
  position: relative;
  top: 2px;
}
