@import '../variables.scss';

.variant-selector--inline {
  display: inline-block;

  @media #{$mobile} {
    display: block;
  }
}

.variant-selector__variant {
  margin-bottom: $padding-vertical;
}

.variant-selector--inline .variant-selector__variant {
  display: inline-block;
  margin-bottom: 0;
  margin-right: $padding-horizontal;

  @media #{$mobile} {
    display: block;
    width: 100%;
  }
}

.variant-selector__select {
  color: $color-text;
  font-size: 16px;
  width: 100%;

  @media #{$mobile} {
    font-size: 14px;
  }
}

.variant-selector--inline .variant-selector__select {
  font-size: 14px;
  width: 220px;

  @media #{$mobile} {
    width: 100%;
  }
}

.variant-selector__input {
  border: 2px solid $color-primary;
  border-radius: 5px;
  color: $color-text;
  font-size: 16px;
  height: 50px;
  margin-bottom: $padding-vertical * 2;
  padding: 0 $padding-horizontal;
  width: 100%;

  @media #{$mobile} {
    font-size: 14px;
  }
}

.variant-selector__input::placeholder {
  color: #A1A1A1;
}

.variant-selector__size {
  background: none;
  border: 0;
  color: $color-primary;
  display: block;
  font-size: 17px;
  margin: $padding-vertical auto;
  margin-bottom: $padding-vertical * 2;
  padding: 0;
  text-transform: none;
}

.variant-selector__size .icon {
  font-size: 28px;
  position: relative;
  top: 6px;
}

.variant-selector__designer {
  margin-bottom: $padding-vertical * 3;
}

/*
 * Override react-select styles
 */

.variant-selector .Select-control {
  border: 2px solid $color-primary;
  height: 50px;
  text-align: left;
}

.variant-selector--inline .Select-control {
  border: 1px solid #A1A1A1;
  height: 39px;
}

.variant-selector .Select-control .Select-value,
.variant-selector .Select-placeholder {
  line-height: 48px;
  padding-left: $padding-horizontal;
}

.variant-selector--inline .Select-control .Select-value,
.variant-selector--inline .Select-placeholder {
  line-height: 38px;
}

.variant-selector .Select-placeholder {
  color: #A1A1A1;
}

.variant-selector .Select-menu-outer {
  border: 2px solid $color-primary;
  border-top: 0;
}

.variant-selector--inline .Select-menu-outer {
  border: 1px solid $color-primary;
}

.variant-selector .Select-option.is-focused {
  background-color: #ffe2cc;
}

.variant-selector .is-focused > .Select-control {
  border-color: $color-primary;
  box-shadow: none;
}

.variant-selector .Select-arrow-zone {
  width: 40px;
}

.variant-selector .Select-arrow-zone i {
  position: relative;
  top: 2px;
}
