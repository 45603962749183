@import '../variables.scss';

.navigation-bar {
  background: #ffffff;
  color: $color-primary;
  height: 50px;
  min-width: 900px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1000;

  @media #{$mobile} {
    min-width: auto;
    position: relative;
  }
}

.navigation-bar__logo {
  left: 0;
  position: absolute;
  top: 0;
  img {
    margin-top: 10px;
    margin-left: 10px;
    height: 30px;
  }
}

.navigation-bar__menu {
  margin: auto;

  @media #{$mobile} {
    background: #ffffff;
    min-height: 150%;
    overflow: hidden;
    position: fixed;
    right: -100%;
    transition: right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000);
    width: 50%;
  }
}

.navigation-bar__menu--visible {
  @media #{$mobile} {
    right: 0;
  }
}

.navigation-bar__menu li {
  @include font-bold();

  display: inline-block;
  font-size: 13px;
  line-height: 50px;
  min-height: 100%;
  padding: 0 $padding-horizontal;
  text-transform: uppercase;
  vertical-align: top;

  @media #{$narrow} {
    padding: 0 $padding-horizontal/2;
    font-size: 12px;
  }

  @media #{$mobile} {
    border-bottom: 1px solid #f8f8f8;
    display: block;
    line-height: 64px;
    padding: 0;
    text-align: left;
    text-transform: none;
    width: 100%;
  }
}

.navigation-bar__link {
  color: $color-primary;
}

.navigation-bar__menu a {
  cursor: pointer;
  position: relative;
  text-decoration: none;

  @media #{$mobile} {
    display: block;
    height: 64px;
    padding: 0 $padding-horizontal;
    width: 100%;
  }
}

.navigation-bar__menu a:after {
  background-color: transparent;
  bottom: -3px;
  content: '';
  display: block;
  left: 50%;
  height: 2px;
  position: absolute;
  transition: background-color, 0.1s ease-out, left 0.1s ease-out, width 0.1s ease-out;
  width: 0;
}

.navigation-bar__menu a:hover:after {
  background-color: $color-primary;
  left: 0;
  width: 100%;
}

.navigation-bar__menu li.navigation-bar__close {
  display: none;

  @media #{$mobile} {
    cursor: pointer;
    display: block;
    height: 50px;
    padding-right: $padding-horizontal;
    text-align: right;
  }
}

.navigation-bar__link a {
  left: 8px;
  position: relative;

  @media #{$mobile} {
    left: 0;
  }
}

.logo--text {
  fill: #ffffff;
}

.logo--icon {
  fill: #ffffff;
}

.logo--rotate {
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  transition: all 0.5s ease-in-out;
}

svg:hover .logo--rotate {
  transform: rotate(360deg);
}

.navigation-bar__actions {
  position: absolute;
  right: 0;
  top: 0;
  @media #{$mobile} {
    right: 50px;
  }
}

.navigation-bar__action {
  position: relative;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 52px;
  width: 55px;
  @media #{$narrow} {
    font-size: 20px;
    width: 40px;
  }
}

.navigation-bar__action a {
  text-decoration: none;
}

.navigation-bar__cart-count {
  background-color: $color-primary;
  border-radius: 20px;
  color: #ffffff;
  display: none;
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  right: 6px;
  top: 5px;
  width: 16px;
  @media #{$narrow} {
    font-size: 8px;
    width: 12px;
    height: 12px;
  }
}

.navigation-bar__cart-count--visible {
  display: block;
}

/*
 * Mobile only stuff
 */

.navigation-bar__hamburger {
  display: none;

  @media #{$mobile} {
    background-color: $color-primary;
    display: block;
    height: 50px;
    padding: 15px 0 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
  }
}

.navigation-bar__hamburger__line {
  background: #ffffff;
  border-radius: 3px;
  display: block;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  width: 29px;
  z-index: 1;
}

.navigation-bar__hamburger--first {
  transform-origin: 0% 0%;
}

.navigation-bar__hamburger--last {
  transform-origin: 0% 100%;
}

.navigation-bar--visible .navigation-bar__hamburger__line {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: $color-primary;
}

.navigation-bar--visible .navigation-bar__hamburger--middle {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.navigation-bar--visible .navigation-bar__hamburger--last {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

.tuxedo-warning {
  width: 100%;
  top: 80px;
  font-weight: bold;
  background-color: white;
  padding: 10px;
  position: absolute;
  font-size: 20px;
  text-align: center;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

