.product-picker {
  text-align: center;
  padding-bottom: 20px;
  button {
    background-color: white;
    border: 2px solid #c9c9c9;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    width: 90px;
    padding: 5px 0;
    color: #c9c9c9;
    position: relative;
    >svg {
      margin: 0 auto 5px auto;
      height: 60px;
      display: block;
      path, polygon {
        fill: #c9c9c9;
      }
    }
    &.active {
      border-color: #4f4f4f;
      color: #4f4f4f;
      >svg {
        path, polygon {
          fill: #4f4f4f;
        }
      }
    }
    >div {
      position: absolute;
      top: 25px;
      left: 0;
      width: 100%;
      text-align: center;
      .fa {
        color: white;
      }
    }
  }
}