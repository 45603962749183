@import '../variables.scss';

.my-account__payments {
  margin: $padding-vertical $padding-horizontal;
}

.my-account__payment-method {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background-color: $color-light-blue;
  font-weight: bold;
  margin-bottom: $padding-vertical;
}

.my-account__payment-method:last-child {
  margin-bottom: 0;
}

.my-account__payment-method a {
  color: $color-primary;
  text-decoration: none;
}

.my-account__payment-method a:hover {
  text-decoration: underline;
}


.my-account__user-credit {
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  padding: 10px;
}

.my-account__show-depleted {
  margin-top: $padding-vertical;
  color: $color-primary;
  font-size: 16px;
  text-align: center;
  display: block;
}

.user-credit__description {
  display: flex;
  justify-content: space-between;
}

.user-credit__type {
  font-weight: bold;
  display: inline-block;
}

.user-credit__amount {
  font-weight: bold;
  color: $color-text;
}

.user-credit__note {
  margin-top: 10px;
  font-size: 16px;
}