@import '../variables.scss';

.my-account__details {
  margin: $padding-vertical $padding-horizontal;
}

.my-account__new-password-fields {
  display: flex;
  & > .checkout__input {
    flex: 1;
    margin-right: $padding-horizontal;
  }
  & > .checkout__input:last-child {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .my-account__new-password-fields {
    display: block;
    & > .checkout__input {
      margin-right: 0;
    }
  }
}