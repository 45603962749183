@import '../variables.scss';

.user-store-wrapper .my-account__header > div {
  width: 33.3%;
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media #{$mobile} {
    width: 100%;
    text-align: center;
    .view-store-stats {
      margin-top: 10px;
    }
  }
}

.user-store-wrapper .error-box {
  margin-top: -10px;
  margin-bottom: 20px;
}

.my-account__header {
  @media #{$mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
  .user-store-header-upload {
    margin-bottom: -40px;
    @media #{$mobile} {
      margin-top: 10px;
    }
  }
  .custom-upload-input {
    transition: background 0.2s linear, opacity 0.15s linear, visibility 0s linear 0.15s;
    opacity: 0;
    input {
      pointer-events: none;
    }
    @media #{$mobile} {
      opacity: 1;
    }
  }
  &:hover .custom-upload-input {
    opacity: 1;
  }
}

.my-account__header .user-store__profile-image {
  margin-top: 90px;
  > label {
    margin-top: 20px;
  }
}

.my-account__header .color-picker__button {
  margin-top: -40px;
  margin-left: 220px;
}

.color-picker__container {
  margin-top: -20px;
}

.user-store-disabled {
  background-color: $color-primary;
  color: white;
  padding: 16px;
  border-radius: 4px;
  text-align: center;
}

.user-store-cta {
  margin-top: -10px;
  text-align: center;
  button {
    margin-top: 20px;
  }  
}

.user-store-info {
  margin-top: -10px;
  text-align: center;
  p {
    margin: 10px auto;
    max-width: 600px;
  }
  button {
    opacity: 0;
    margin-top: -10px;
    @media #{$mobile} {
      opacity: 1;
      margin-top: 10px;
    }
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
}

.user-store-form {
  max-width: 600px;
  margin: auto;

  label {
    color: $color-light-gray;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: 6px;
  }

  .user-store-url {
    display: flex;
    align-items: center;
    margin-bottom: $padding-vertical * 2;
      @media #{$mobile} {
        flex-direction: column;
        .checkout__input {
          width: 100%;
        }
      }
    .user-store-url-prefix {
      font-size: 18px;
      font-weight: bold;
      color: $color-light-gray;
      margin-right: 5px;
    }
    .checkout__input {
      flex-grow: 1;
      .input {
        margin-bottom: 0;
      }
    }
  }
}

.user-store-form__buttons {
  display: flex;
  button[type=submit] {
    .fa {
      margin-right: 6px;
    }
    margin-right: 6px;
    flex-grow: 1;
  }
}

.user-store-link {
  word-break: break-all;
  line-height: 24px;
  .fa {
    margin-left: 8px;
  }
}

.my-product-details {
  .user-product-info:not(:first-child) {
    border-radius: 0 !important;
    border-top: none;
  }
}

.my-product-toggle {
  background-color: white;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $color-border;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @include font-bold();
}

.product-selling-toggle__disabled {
  line-height: 30px;
  justify-content: center;
}

.product-description-size-chart {
  height: 50px;
}

.my-store-footer {
  background-color: white;
  padding: 40px;
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  .my-store-footer__steps {
    display: flex;
    justify-content: space-evenly;
    >div {
      text-align: center;
      img {
        margin-bottom: 10px;
      }
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      flex-basis: 20%;
    }
    @media #{$mobile} {
      flex-direction: column;
      div {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
    }
  }
}