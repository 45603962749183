@import '../variables.scss';

.review {
  background-color: $color-background-gray;
  min-height: 100%;
}

.review__content {
  padding: 70px 0 104px 0;
  margin: auto;
  width: 1024px;
  text-align: center;

  @media #{$mobile} {
    padding: $padding-vertical $padding-horizontal 104px $padding-horizontal;
    width: 100%;
  }
}

.review__hero {
  background-color: $color-photo-background;
  border-radius: 5px;
  height: 300px;
  margin-bottom: 23px;
  padding-left: 430px;
  padding-top: 100px;
  position: relative;
  text-align: left;
  width: 100%;

  @media #{$mobile} {
    height: auto;
    padding: 0;
  }
}

.review__hero__image {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;

  @media #{$mobile} {
    position: inherit;
  }
}

.review__hero__image img {
  height: 100%;

  @media #{$mobile} {
    height: auto;
    width: 100%;
  }
}

.review__hero__text {
  @media #{$mobile} {
    padding: $padding;
    text-align: center;
  }
}

.review__hero__descriptor {
  padding-right: $padding-horizontal * 4;

  @media #{$mobile} {
    padding-right: 0;
  }
}

.review__title {
  margin-bottom: $padding-vertical * 1.5;

  @media #{$mobile} {
    font-size: 20px;
    margin-bottom: $padding-vertical;
  }
}

.review__hero p {
  margin-bottom: 4px;

  @media #{$mobile} {
    font-size: 14px;
  }
}

.review__hero--orange {
  color: $color-primary;
}
