@import '../variables.scss';

.cart__container {
  background: $color-background-gray;
  min-height: 100%;
}

.cart {
  margin: auto;
  padding-bottom: $padding-vertical * 4;
  max-width: 1024px;

  @media #{$narrow} {
    padding: $padding;
    width: 100%;
  }
}

.cart__header {
  font-size: 32px;
  margin-bottom: $padding-vertical;
  padding-top: 130px;
  text-align: center;

  @media #{$narrow} {
    padding-top: 50px;
  }
}

.cart__left {
  float: left;
  width: 656px;

  @media #{$narrow} {
    float: none;
    width: 100%;
  }
}

.cart__right {
  float: right;
  width: 336px;

  @media #{$narrow} {
    float: none;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
}
