@import '../variables.scss';

.editor-button.template-button {
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  label {
    position: absolute;
    top: 2px;
    left: 16px;
    font-size: 10px;
    color: #888;
  }
  padding-top: 12px;
  padding-bottom: 6px;
  height: auto;
  div {
    overflow: visible;
  }
}

.template-picker__modal {
  .header {
    margin-bottom: 16px;
  }
  .variant-selector__designer {
    margin-bottom: 16px;
  }
  .upload-variants {
    margin: 0 10px;
    @media #{$desktop} {
      margin: 0;
    }
  }
  overflow-y: auto;
  @media #{$desktop} {
    max-width: 700px;
  }
}

.template-picker__buttons {
  display: flex;
  button {
    flex-basis: 150px;
  }
  justify-content: center;
  @media #{$desktop} {
    justify-content: flex-end;
  }
}