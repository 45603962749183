@import '../variables.scss';

.preview-group {
  .save-design, .add-to-cart {
    display: none;
  }
  .editor-button {
    margin-bottom: 10px;
  }
  .preview-frame {
    padding: 20px;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .preview-canvas-container {
    position: relative;
    svg {
      position: absolute;
      top: 0; left: 0;
      stroke: rgba(0,0,0,0.5);
      stroke-width: 18;
      polygon, path {
        fill: none;
      }
    }
  }
  .template-warning {
    position: absolute;
    bottom: 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    >div {
      margin-bottom: 10px;
    }
    .fa {
      font-size: 26px;
    }
    animation: bounce 0.2s ease infinite alternate;
  }
  @media #{$desktop} {
    width: #{$preview_width};
    .save-design, .add-to-cart {
      display: flex;
      margin-bottom: 10px;
    }
    .preview-frame {
      width: #{$preview_width};
    }
  }
  @keyframes bounce {
    0% { transform: translateY(0px); }
    100% { transform: translateY(-10px); }
  }
}