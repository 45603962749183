@import '../variables.scss';

.continue-bar {
  background: #ffffff;
  border-top: 1px solid $color-border;
  bottom: 0;
  padding: $padding;
  position: fixed;
  width: 100%;
}

.ReactModalPortal .continue-bar {
  position: absolute;
  left: 0;
}

.continue-bar__content {
  margin: auto;
  max-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{$mobile} {
    flex-direction: column;
  }
}

.continue-bar__back {
  background: none;
  border: 1px solid $color-border;
  color: $color-text;

  @media #{$mobile} {
    display: none;
  }
}

.continue-bar__checkbox {
  flex: 1;
  text-align: right;
  margin: 0 $padding-horizontal;
  @media #{$mobile} {
    text-align: center;
    margin: 0 0 $padding-vertical 0;
  }
}

.continue-bar__action {
  @media #{$mobile} {
    width: 100%;
  }
}

.continue-bar__action-disabled,
.continue-bar__action:disabled {
  background: #cfcfcf !important;
  cursor: default;
}

.continue-bar .icon-arrow-b {
  display: inline-block;
  margin-left: $padding-horizontal;
  transform: rotate(270deg);
}
