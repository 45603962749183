@import '../variables.scss';

$field-padding: 4px;

.checkout-field {
  position: relative;
}

.checkout-field--has-error .input,
.checkout-field--has-error .Select-control {
  border-color: #FF0000;
}

.checkout-field__error {
  background: #ffffff;
  color: #ff0000;
  font-size: 14px;
  left: $padding-horizontal - $field-padding + 1;
  padding: 0 $field-padding;
  position: absolute;
  top: -7px;
}
