@import '../variables.scss';

.cart-line-items {
  width: 100%;
}

.cart-line-items__group {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  padding: $padding;
  padding-bottom: 0;
}

.cart-line-items__group__cta {
  border-top: 1px solid $color-border;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin: 0 (-1 * $padding-horizontal);
  padding: ($padding-vertical * 1.5) $padding-horizontal;
  text-decoration: none;
  .icon, span {
    color: $color-primary;
  }
}

.cart-line-items__cta {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical;
  padding: $padding;
  text-decoration: none;
  color: $color-primary;
}

.cart-line-items__cta-button {
  font-size: 30px;
  color: white;
  background-color: $color-primary;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 80px;
  flex-shrink: 0;
}

.cart-line-items__group .icon-copy {
  font-size: 20px;
  margin-right: 6px;
  top: 4px;
}
