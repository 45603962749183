@import '../variables.scss';

.checkout-box {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: $padding-vertical * 2;
}

.checkout-box__title {
  border-bottom: 1px solid $color-border;
  font-size: 22px;
  padding: $padding;
  text-align: center;
}

.checkout-box__children {
  padding: $padding-double;

  @media #{$mobile} {
    padding: $padding;
  }
}

.checkout-box .warning-message {
  background-color: transparent;
  border: 1px solid $color-primary;
  padding: 5px;
  color: black;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: $padding-vertical * 2;
}
