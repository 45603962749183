$color-primary: #ff6f00;
$color-teal: #09d8b0;
$color-yellow: #ffe000;
$color-magenta: #ea427f;

$color-dark: #4f4f4f;
$color-light: #eaeaea;

$color-text: #4f4f4f;
$color-white: #ffffff;
$color-background-gray: #f8f8f8;
$color-light-gray: #919191;
$color-light-orange: #fdf5f3;
$color-light-red: #fff3f3;
$color-error: #d71b5d;
$color-info: #1485CC;
$color-border: #e9e9e9;
$color-photo-background: #D9E2E4;
$color-green: #7ED321;
$color-light-blue: #eafcf8;
$color-affiliate-green: #04D554;
$color-affiliate-lt-green: #08DD1D;

$padding-horizontal: 16px;
$padding-vertical: 16px;
$padding: $padding-vertical $padding-horizontal;
$padding-plushalf: ($padding-vertical * 1.5) ($padding-horizontal * 1.5);
$padding-double: ($padding-vertical * 2) ($padding-horizontal * 2);

$menu-height: 50px;

@mixin font-normal() {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin font-header() {
  font-family: 'Arvo', serif;
  font-style: normal;
  font-weight: 400;
}

@mixin font-light() {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin font-bold() {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
}

$desktop: (min-width: 830px);
$mobile: (max-width: 768px);
$narrow: (max-width: 1080px);
$mobile-phone: (max-width: 400px);

// PixelThis Editor
$preview_width: 180px;
$canvas_width: 528px;
$tools_width: 90px;
