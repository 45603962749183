@import '../variables.scss';

.gift-select {
  background-color: $color-background-gray;
  min-height: 100%;
}

.gift-select__content {
  padding: 0;
  padding-top: 100px;
  padding-bottom: $padding-vertical * 7;
  margin: auto;
  width: 770px;
  text-align: center;

  @media #{$mobile} {
    padding: 40px $padding-horizontal 104px $padding-horizontal;
    width: 100%;
  }
}

.gift-select__title {
  margin: $padding-double;

  @media #{$mobile} {
    font-size: 20px;
  }
}

.gift-select__remove {
  background: #ffffff;
  color: $color-dark;
  font-size: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    color: black;
  }
}

.gift-select__form {
  position: relative;
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  float: left;
  margin-bottom: 24px;
  margin-right: 24px;
  overflow: hidden;
  text-align: left;
  width: 100%;

  @media #{$mobile} {
    margin-bottom: $padding-vertical;
  }

  .preset-price-selector {
    display: flex;
    flex-wrap: wrap;
    justify-content: between;
    .gift-price-calculator {
      width: 250px;
      margin-right: 16px;
      button {
        width: 100%;
      }
    }
    input {
      flex-grow: 1;
      margin-right: 0;
    }
    @media #{$mobile} {
      justify-content: space-between;
      button {
        flex-basis: calc(50% - 8px);
        margin-right: 0;
      }
      input {
        flex-grow: 1;
        margin-right: 0;
      }
    }
  }

}

.gift-select__add {
  width: 100%;
  color: $color-text;
  >* {
    transition: opacity 0.2s;
    opacity: 0.7;
  }
  font-size: 16px;
  text-align: center;
  .icon {
    color: $color-primary;
    font-size: 35px;
    font-weight: lighter;
  }
}

.gift-select__add:hover {
  >* {
    opacity: 1;
  }
}

.gift-select__box {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
  float: left;
  margin-bottom: 24px;
  margin-right: 24px;
  overflow: hidden;
  text-align: left;
  width: 500px;

  @media #{$mobile} {
    margin-bottom: $padding-vertical;
    width: 100%;
  }
}

.gift-select__box:last-child {
  margin-right: 0;
}

.gift-select__box-content {
  padding: $padding-plushalf;
}

.gift-select__box-content h4 {
  @media #{$mobile} {
    max-width: 80%;
  }
}

.gift-select__box-content p {
  margin-top: $padding-vertical;
}

.gift-select__image {
  background-color: $color-photo-background;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 167px;
}

.gift-select__image--product {
  background-image: url('../../assets/products/gift-select-dress.png');
  background-size: 189px 147px;
}

.gift-select__image--doll {
  background-image: url('../../assets/products/gift-select-addon.png');
  background-size: 166px 148px;
}

.gift-select__box-price {
  position: absolute;
  right: 24px;
  top: 24px;
}

.gift-select__box-description {
  min-height: 62px;
}

.gift-select__quantity {
  padding-top: $padding-vertical * 2;
  text-align: center;
}

.gift-select__box-content .quantity-selector__button {
  background-color: #CFCFCF;
  border: 0;
  color: #ffffff;
  font-size: 28px;
  height: 34px;
  width: 34px;
}


.gift-select__box-content .quantity-selector__button:last-child,
.gift-select__box-content .quantity-selector__decrement--above-zero {
  background-color: $color-primary;
}

.gift-select__box-content .quantity-selector__increment--disabled {
  background-color: #CFCFCF !important;
}

.gift-select__shipping {
  margin-bottom: 24px;
}

.gift-select__shipping h4 {
  margin-bottom: $padding-vertical * 1.5;
}

.gift-select__shipping-item {
  cursor: pointer;
  height: 30px;
  padding-left: 46px;
  position: relative;
  margin-top: $padding-vertical;

  @media #{$mobile} {
    height: auto;
  }
}

.gift-select__shipping-item .radio {
  left: 0;
  position: absolute;
  top: 0;

  @media #{$mobile} {
    top: 1px;
  }
}

.gift-select__shipping-item span {
  display: inline-block;
  padding-top: 5px;
}

.gift-select__shipping-label {
  padding-right: 15px;
}

.gift-select__shipping-price {
  float: right;

  @media #{$mobile} {
    float: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

textarea.gift-select__message {
  height: 182px;
}

.gift-select__summary {
  font-size: 20px;
}

.gift-select__summary-price {
  float: right;
}

.gift-select__field-error {
  color: $color-error;
  margin-top: $padding-vertical * -1.5;
  margin-bottom: $padding-vertical * 2;
  @media #{$mobile} {
    margin-top: $padding-vertical * -0.5;
    margin-bottom: $padding-vertical;
  }
}

.gift-select__price-error {
  color: $color-error;
  margin-top: $padding-vertical * -0.4;
}