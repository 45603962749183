@import '../variables.scss';

.product-selector {
  display: flex;
  justify-content: space-between;
  @media #{$mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.product-selector__product {
  background-color: #ffffff;
  border: 2px solid $color-border;
  border-radius: 5px;
  display: inline-block;
  min-height: 120px;
  margin-bottom: 10px;
  .product-selector__image,
  .product-selector__title {
    opacity: 0.3;    
  }
  padding: 0;
  position: relative;
  @media #{$mobile} {
    margin: 5px;
  }
}

.product-selector__product--active {
  border: 2px solid #484848;
  .product-selector__image,
  .product-selector__title {
    opacity: 1;
  }
}

.product-selector__product--unavailable {
  .product-selector__image,
  .product-selector__title {
    opacity: 0.3;
  }
}

.product-selector__title {
  font-size: 16px;
  display: block;
  padding: 10px;
}

.product-selector__notice {
  color: red;
  font-weight: bold;
  position: absolute;
  top: 40%;
  font-size: 14px;
  text-align: center;
}

.product-selector__image {
  background-position: center center;
  background-repeat: no-repeat;
  height: 65px;
  margin: 0 auto 0 auto;
  width: 90px;
}

.product-selector__product--shirt .product-selector__image {
  background-image: url('../../assets/products/selector/shirt-inactive.svg');
}

.product-selector__product--shirt.product-selector__product--active .product-selector__image {
  background-image: url('../../assets/products/selector/shirt-active.svg');
}

.product-selector__product--dress .product-selector__image {
  background-image: url('../../assets/products/selector/dress-inactive.svg');
}

.product-selector__product--dress.product-selector__product--active .product-selector__image {
  background-image: url('../../assets/products/selector/dress-active.svg');
}

.product-selector__product--leggings .product-selector__image {
  background-image: url('../../assets/products/selector/leggings-inactive.svg');
}

.product-selector__product--leggings.product-selector__product--active .product-selector__image {
  background-image: url('../../assets/products/selector/leggings-active.svg');
}

.product-selector__product--beanie .product-selector__image {
  background-image: url('../../assets/products/selector/beanie-inactive.svg');
}

.product-selector__product--beanie.product-selector__product--active .product-selector__image {
  background-image: url('../../assets/products/selector/beanie-active.svg');
}

.product-selector__product--mask .product-selector__image {
  background-image: url('../../assets/products/selector/mask-inactive.svg');
}

.product-selector__product--mask.product-selector__product--active .product-selector__image {
  background-image: url('../../assets/products/selector/mask-active.svg');
}