@import '../variables.scss';

.my-account__container {
  background: #f8f8f8;
  min-height: 100%; }

.my-account {
  margin: auto;
  padding-bottom: 64px;
  max-width: 1024px;
  margin-top: -225px; 
  position: relative;
}
  @media (max-width: 768px) {
    .my-account {
      padding: 16px 16px;
      padding-bottom: 202px;
      width: 100%; 
    } 
  }

.my-account__header {
  color: white;
  .header {
    font-size: 34px;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 45px;
}
  @media (max-width: 768px) {
    .my-account__header {
      flex-direction: column;
    } 
  }

.my-account__breadcrumbs {
  position: relative;
  >nav {
    position: absolute;
    top: 0;
    right: 0;
    white-space: nowrap;
    @media #{$mobile} {
      white-space: normal;
    }
  }
  font-size: 14px;
  @include font-bold();
  min-height: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  .my-account__dropdown-wrapper {
    cursor: pointer;
  }
  .my-account__dropdown-menu {
    position: absolute;
    right: 0;
    width: 260px;
    margin-top: 6px;
    border-radius: 5px;
    border: 1px solid $color-border;
    background-color: white;
    color: $color-text;
    padding: $padding-vertical;
    z-index: 1;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    line-height: 32px;
    font-weight: normal;
    >div:hover {
      font-weight: bold;
    }
  }
  a {
    text-decoration: none;
  }
  .my-account__dropdown-wrapper, a {
    &:not(:last-child) {
      margin-right: 6px;
      &::after {
        content: '/';
        margin-left: 6px;
      }
    }
  }
}
  @media (max-width: 768px) {
    .my-account__breadcrumbs {
      width: 100%;
      margin-top: 6px;
      justify-content: center;
    } 
  }

.my-account__content {
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 5px;
}

.my-account__right {
  width: 680px; 
}
  @media (max-width: 768px) {
    .my-account__right {
      width: 100%; 
    } 
  }

.my-account__menu {
  width: 700px;
  margin: auto;
}
  @media (max-width: 768px) {
    .my-account__menu {
      width: 100%; 
    } 
  }

.my-account__nav-link {
  display: block;
  border-bottom: 1px solid $color-border;
  padding: $padding-vertical $padding-horizontal;
  text-decoration: none;
  font-weight: bold;
  border-right: 8px solid transparent;
  &:hover {
    border-right: 8px solid $color-primary;
  }
  .beta-text {
    color: $color-dark;
    margin-left: 3px;
    vertical-align: super;
    font-size: 12px;
    font-style: oblique;
  }
}

.my-account__log-out {
  cursor: pointer;
}

.color-picker__button {
   color: white;
   border-color: white;
}

.color-picker {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;
  justify-content: center;
  width: 115px;
  padding: 10px 0;
  background-color: white;
  border-radius: 5px;
}

.color-picker__swatch {
  width: 25px;
  height: 25px;
  margin: 3px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Toastify__toast-body {
  @include font-normal();
  font-weight: bold;
}
.Toastify__toast {
  border-radius: 5px;
  padding: $padding-horizontal;
}
.Toastify__toast--success {
  background-color: $color-green;
}
.Toastify__toast--info {
  background-color: $color-info;
}
.Toastify__toast--warning {
  background-color: $color-error;
}
.Toastify__toast--error {
  background-color: $color-error;
}